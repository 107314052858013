import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { MenuOutlined } from '@ant-design/icons';
import { Routes, Route, Link, Navigate, useMatch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import appLogo from 'assets/app-logo.png';

import {
  ACTIVITY_EDIT_ROUTE,
  ACTIVITY_VOTES_STATS_ROUTE,
  ACTIVITY_GENERAL_STATS_ROUTE,
  ACTIVITY_WINNER_STATS_ROUTE,
  LOGIN_ROUTE,
  PROGRAM_EDIT_ROUTE,
  PROGRAM_LINEUP_ROUTE,
  PROGRAM_ROUTE,
  PROGRAM_STATS_GENERAL_ROUTE,
  PROGRAM_STATS_ROUTE,
  PROGRAM_STATS_WINNER_ROUTE,
  PROGRAMS_ALL_ROUTE,
  PROGRAMS_ARCHIVE_ROUTE,
  PROGRAMS_ROUTE,
  ROOT_ROUTE,
  USERS_ROUTE,
  ACTIVITY_VOTES_BACKUP_STATS_ROUTE,
  PROGRAM_LINEUP_VIEWER_ROUTE,
  XML_VOTE_ROUTE,
} from 'common/constants/routes';
import Page from 'common/components/Page';
import Button from 'common/components/Button';
import Private from 'common/components/PrivateRoute';
import Nav from 'common/components/SideNavigation';

import Login from 'features/user/Login';
import Lineup from 'features/program/Lineup';
import Program from 'features/program/Program';
import Stats from 'features/program/Stats';
import Winners from 'features/program/Stats/Winners';
import General from 'features/program/Stats/General';
import Votes from 'features/program/Stats/Votes';
import XmlVotesBackup from 'features/program/XmlVotesBackup';
import ProgramContent from 'features/program/ProgramContent';
import { programNavigation } from 'features/program/constants';
import EditActivity from 'features/program/Lineup/EditActivity';
import Programs, { programsNavigation } from 'features/programs/Programs';
import { selectIsAsideCollapsed, setIsAsideCollapsed } from 'features/user/userSlice';
import { useAppDispatch } from './hooks';
import css from './App.module.css';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Users = () => (
  <>
    <Page.Header>
      <h1 className="text-title">Users</h1>
    </Page.Header>
    <Page.Content>data...</Page.Content>
  </>
);

const App = () => {
  const isStats = useMatch('program/:programId/stats/*') !== null;
  const isStatsXml = useMatch(XML_VOTE_ROUTE) !== null;
  const dispatch = useAppDispatch();
  const isAsideCollapsed = useSelector(selectIsAsideCollapsed);

  return (
    <div className={cn(css.app, { [css.asideCollapsed]: isAsideCollapsed, [css.stats]: isStats || isStatsXml })}>
      <div className={css.aside}>
        <Button
          type="text"
          className={css.btn}
          icon={<MenuOutlined />}
          onClick={() => dispatch(setIsAsideCollapsed(!isAsideCollapsed))}
        />

        <Link to={ROOT_ROUTE} className={css.logo}>
          <img src={appLogo} alt="Keshet LIVE logo" />
        </Link>

        {/* Navigation */}
        <SentryRoutes>
          <Route path={PROGRAM_EDIT_ROUTE} element={<Private element={<Nav {...programNavigation} />} />} />
          <Route path={PROGRAM_LINEUP_ROUTE} element={<Private element={<Nav {...programsNavigation} />} />} />
          <Route path={PROGRAM_LINEUP_VIEWER_ROUTE} element={<Private element={<Nav {...programsNavigation} />} />} />
          <Route path={ACTIVITY_EDIT_ROUTE} element={<Private element={<Nav {...programsNavigation} />} />} />
          <Route path={PROGRAMS_ROUTE} element={<Private element={<Nav {...programsNavigation} />} />} />
          <Route path={PROGRAMS_ALL_ROUTE} element={<Private element={<Nav {...programsNavigation} />} />} />
          <Route path={PROGRAMS_ARCHIVE_ROUTE} element={<Private element={<Nav {...programsNavigation} />} />} />
          <Route path={USERS_ROUTE} element={<Private element={<Nav {...programsNavigation} />} />} />
          <Route path="*" element={null} />
        </SentryRoutes>
      </div>

      {/* Routes */}
      <div className={css.content}>
        <SentryRoutes>
          <Route path={LOGIN_ROUTE} element={<Login />} />
          <Route path={XML_VOTE_ROUTE} element={<XmlVotesBackup />} />
          <Route path={PROGRAMS_ROUTE} element={<Private element={<Programs.Layout />} />}>
            <Route index element={<Private element={<Navigate to={PROGRAMS_ALL_ROUTE} />} />} />
            <Route path={PROGRAMS_ALL_ROUTE} element={<Private element={<Programs.Table active />} />} />
            <Route path={PROGRAMS_ARCHIVE_ROUTE} element={<Private element={<Programs.Table />} />} />
          </Route>
          <Route path={PROGRAM_ROUTE} element={<Private element={<Program />} />}>
            <Route path={PROGRAM_EDIT_ROUTE} element={<Private element={<ProgramContent />} />} />
            <Route path={PROGRAM_LINEUP_ROUTE} element={<Private element={<Lineup />} />} />
            <Route path={PROGRAM_LINEUP_VIEWER_ROUTE} element={<Private element={<Lineup />} />} />
            <Route path={ACTIVITY_EDIT_ROUTE} element={<Private element={<EditActivity />} />} />
            {/* stats */}
            <Route path={PROGRAM_STATS_ROUTE} element={<Private element={<Stats />} />}>
              {/* program stats */}
              <Route path={PROGRAM_STATS_WINNER_ROUTE} element={<Private element={<Winners type="program" />} />} />
              <Route path={PROGRAM_STATS_GENERAL_ROUTE} element={<Private element={<General type="program" />} />} />
              {/* activity stats */}
              <Route path={ACTIVITY_WINNER_STATS_ROUTE} element={<Private element={<Winners type="activity" />} />} />
              <Route path={ACTIVITY_GENERAL_STATS_ROUTE} element={<Private element={<General type="activity" />} />} />
              <Route path={ACTIVITY_VOTES_STATS_ROUTE} element={<Private element={<Votes type="main" />} />} />
              <Route path={ACTIVITY_VOTES_BACKUP_STATS_ROUTE} element={<Private element={<Votes type="backup" />} />} />
            </Route>
          </Route>
          <Route path={USERS_ROUTE} element={<Private element={<Users />} />} />
          <Route path="*" element={<Navigate to={LOGIN_ROUTE} replace />} />
        </SentryRoutes>
      </div>
    </div>
  );
};
export default App;
