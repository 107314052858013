import React from 'react';
import * as y from 'yup';
import { FormikProps } from 'formik';
import { Row, Col } from 'antd';
import cn from 'classnames';
import { YesNoActivity, YesNoActivityInput, ActivityTypes } from 'app/api/generated';
import Upload from 'common/components/Upload';
import InputText from 'common/components/InputText';
import InputColor from 'common/components/InputColor';
import Toggle, { getToggleFieldProps } from 'common/components/Toggle';
import { MAX_ACTIVITY_NAME_LEN } from 'features/program/validations';
import programCSS from 'features/program/Program.module.css';
import css from 'features/program/Lineup/Activities/YesNo.module.css';

const YesNo = ({ form }: { form: FormikProps<Partial<YesNoActivity>> }) => {
  return (
    <div style={{ width: 530, maxWidth: '100%' }}>
      <InputText
        {...form.getFieldProps('name')}
        touched={form.touched.name}
        error={form.errors.name}
        label="Activity name"
        maxLength={MAX_ACTIVITY_NAME_LEN}
        showCount
        placeholder="Type your Activity name"
        autoComplete="off"
        className={programCSS.field}
      />
      <InputText
        {...form.getFieldProps('xmlVoteId')}
        touched={form.touched.xmlVoteId}
        error={form.errors.xmlVoteId}
        label="XML vote ID"
        placeholder="XML_name_mako"
        autoComplete="off"
        className={programCSS.field}
      />
      <Upload
        {...form.getFieldProps('mainImage')}
        touched={form.touched.mainImage}
        error={form.errors.mainImage}
        accept="image/png, image/jpeg"
        defaultPlaceholder="Image"
        limit={300}
        placeholder={<p>JPG, PNG | 300KB</p>}
        label="Artist image"
        onFileChange={form.setFieldValue}
        className={programCSS.field}
      />
      <InputText
        {...form.getFieldProps('questionText')}
        touched={form.touched.questionText}
        error={form.errors.questionText}
        label="Question text"
        placeholder="האם נטע תרים את המסך?"
        autoComplete="off"
        className={programCSS.field}
      />
      <InputColor
        {...form.getFieldProps('mainColor')}
        touched={form.touched.mainColor}
        error={form.errors.mainColor}
        label="Text color"
        placeholder="#FFFFFF"
        autoComplete="off"
        className={programCSS.field}
        onColorChange={form.setFieldValue}
      />
      <Toggle
        {...getToggleFieldProps(form.getFieldProps('showFlare'))}
        touched={form.touched.showFlare}
        error={form.errors.showFlare}
        label="Show flare"
        className={programCSS.field}
        onChange={form.setFieldValue}
      />
      <Row gutter={16}>
        <Col span={12}>
          <InputText
            {...form.getFieldProps('yesText')}
            touched={form.touched.yesText}
            error={form.errors.yesText}
            label="Vote passed"
            placeholder="עברה!"
            autoComplete="off"
            className={programCSS.field}
          />
        </Col>
        <Col span={12}>
          <InputText
            {...form.getFieldProps('noText')}
            touched={form.touched.noText}
            error={form.errors.noText}
            label="Vote not passed"
            placeholder="לא עברה!"
            autoComplete="off"
            className={programCSS.field}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <InputColor
            {...form.getFieldProps('yesBgColor')}
            touched={form.touched.yesBgColor}
            error={form.errors.yesBgColor}
            placeholder="#FFFFFF"
            autoComplete="off"
            className={programCSS.field}
            onColorChange={form.setFieldValue}
          />
        </Col>
        <Col span={12}>
          <InputColor
            {...form.getFieldProps('noBgColor')}
            touched={form.touched.noBgColor}
            error={form.errors.noBgColor}
            placeholder="#FFFFFF"
            autoComplete="off"
            className={programCSS.field}
            onColorChange={form.setFieldValue}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Upload
            {...form.getFieldProps('yesIcon')}
            touched={form.touched.yesIcon}
            error={form.errors.yesIcon}
            accept="image/png, image/jpeg"
            defaultPlaceholder={null}
            placeholder={
              <p>
                Icon image <br />
                GIF, JPG, PNG | 300KB
              </p>
            }
            limit={300}
            onFileChange={form.setFieldValue}
            className={cn(programCSS.field, css.iconUpload)}
            preset="preset1"
          />
        </Col>
        <Col span={12}>
          <Upload
            {...form.getFieldProps('noIcon')}
            touched={form.touched.noIcon}
            error={form.errors.noIcon}
            accept="image/png, image/jpeg, image/gif"
            defaultPlaceholder={null}
            placeholder={
              <p>
                Icon image <br />
                GIF, JPG, PNG | 300KB
              </p>
            }
            limit={300}
            onFileChange={form.setFieldValue}
            className={cn(programCSS.field, css.iconUpload)}
            preset="preset1"
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <InputText
            {...form.getFieldProps('thankYou')}
            touched={form.touched.thankYou}
            error={form.errors.thankYou}
            label="Thank you"
            placeholder="תודה שהשתתפתם"
            autoComplete="off"
            className={css.field}
          />
        </Col>
        <Col span={12}>
          <InputText
            {...form.getFieldProps('continue')}
            touched={form.touched.continue}
            error={form.errors.continue}
            label="Continue"
            placeholder="מיד ממשיכים"
            autoComplete="off"
            className={css.field}
          />
        </Col>
      </Row>
    </div>
  );
};

export const ValidationSchema: y.SchemaOf<YesNoActivityInput> = y.object().shape({
  name: y.string().required('Required'),
  mainColor: y.string().required('Required'),
  mainImage: y.string().required('Required'),
  noBgColor: y.string().required('Required'),
  noIcon: y.string().optional().nullable(),
  noText: y.string().default(''),
  questionText: y.string().required('Required'),
  xmlVoteId: y.string().optional().nullable(),
  yesBgColor: y.string().required('Required'),
  yesIcon: y.string().optional().nullable(),
  yesText: y.string().default(''),
  thankYou: y.string().optional().nullable().default(''),
  continue: y.string().optional().nullable().default(''),
  showFlare: y.boolean().optional().nullable(),
});

export const initialValues: Partial<YesNoActivity> = {
  type: ActivityTypes.YesNo,
  xmlVoteId: '',
  yesText: '',
  noText: '',
  thankYou: '',
  continue: '',
  mainColor: 'rgba(255, 255, 255, 1)',
  yesBgColor: 'rgba(255, 255, 255, 1)',
  noBgColor: 'rgba(255, 255, 255, 1)',
  showFlare: false,
};

export default YesNo;
