// routes
export const ROOT_ROUTE = '/';
export const LOGIN_ROUTE = `${ROOT_ROUTE}login`;
export const PROGRAMS_ROUTE = `${ROOT_ROUTE}programs`;
export const PROGRAMS_ALL_ROUTE = `${ROOT_ROUTE}programs/all`;
export const PROGRAMS_ARCHIVE_ROUTE = `${ROOT_ROUTE}programs/archive`;
export const PROGRAM_ROUTE = `${ROOT_ROUTE}program/:programId`;
export const PROGRAM_EDIT_ROUTE = `${ROOT_ROUTE}program/:programId/edit`;
export const PROGRAM_LINEUP_ROUTE = `${ROOT_ROUTE}program/:programId/lineup`;
export const PROGRAM_LINEUP_VIEWER_ROUTE = `${ROOT_ROUTE}program/:programId/lineup_viewer`;
export const ACTIVITY_EDIT_ROUTE = `${ROOT_ROUTE}program/:programId/activity/:activityId`;

export const PROGRAM_STATS_ROUTE = `${ROOT_ROUTE}program/:programId/stats`;

export const PROGRAM_STATS_WINNER_ROUTE = `${ROOT_ROUTE}program/:programId/stats/winner`;
export const PROGRAM_STATS_GENERAL_ROUTE = `${ROOT_ROUTE}program/:programId/stats/general`;

export const ACTIVITY_WINNER_STATS_ROUTE = `${ROOT_ROUTE}program/:programId/stats/:activityId/winner`;
export const ACTIVITY_GENERAL_STATS_ROUTE = `${ROOT_ROUTE}program/:programId/stats/:activityId/general`;
export const ACTIVITY_VOTES_STATS_ROUTE = `${ROOT_ROUTE}program/:programId/stats/:activityId/votes`;
export const ACTIVITY_VOTES_BACKUP_STATS_ROUTE = `${ROOT_ROUTE}program/:programId/stats/:activityId/votes_backup`;
export const USERS_ROUTE = `${ROOT_ROUTE}users`;
export const XML_VOTE_ROUTE = `${ROOT_ROUTE}__program__/:programId/eG1s`;
