import React, { ReactNode } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { Affix, Space, Spin, Statistic } from 'antd';
import { FileOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { find, sumBy } from 'lodash';
import { useGetProgramStatsQuery } from 'app/api';
import { Activity, ProgramStats, ActivityTypes, ProgramActivityVoteStats } from 'app/api/generated';
import Button from 'common/components/Button';
import { useStatsContext } from 'features/program/Stats';
import { RisingStartAnswerId, YesNoAnswerId } from 'features/program/types';
import css from './Stats.module.css';
import Badge from '../Lineup/Badge';

const Section: React.FC<{ name?: ReactNode; data: Array<{ label: string; value?: number }> }> = (props) => {
  return (
    <section className={css.section}>
      {props.name && <h3 className="text-subheading text-center">{props.name}</h3>}
      {props.data.map(({ value, label }) => {
        return <Statistic title={label} value={value ?? '-'} key={`stats-${props.name}-${label}`} />;
      })}
    </section>
  );
};

function getProgramGeneralStats(data: ProgramStats) {
  return {
    common: () => [
      {
        label: 'Total votes',
        value: sumBy(
          data.activities.reduce<ProgramActivityVoteStats[]>((memo, item) => {
            if (item.votes) memo.push(...item.votes);
            return memo;
          }, []),
          'total'
        ),
      },
      { label: 'Total load', value: data.views },
      // { label: 'Unique user' },
    ],
    registration: () => data.registrations?.map((o) => ({ label: o.type, value: o.total })) ?? [],
  };
}

function getActivityGeneralStats(data: ProgramStats, activity: Activity) {
  const stats = find(data.activities, ['id', activity.id]);

  switch (activity.type) {
    case ActivityTypes.YesNo:
      return [
        { label: 'Total votes', value: sumBy(stats?.votes, 'total') },
        { label: 'Total vote for yes', value: find(stats?.votes, ['answerId', YesNoAnswerId.Yes])?.total },
        { label: 'Total vote for no', value: find(stats?.votes, ['answerId', YesNoAnswerId.No])?.total },
        { label: 'Total views', value: stats?.views },
        { label: 'Total vote when card close' },
      ];
    case ActivityTypes.RisingStar:
      return [
        { label: 'Total check in', value: stats?.checkins },
        { label: 'Total votes', value: sumBy(stats?.votes, 'total') },
        { label: 'Total vote for yes', value: find(stats?.votes, ['answerId', RisingStartAnswerId.Yes])?.total },
        { label: 'Total vote for no', value: find(stats?.votes, ['answerId', RisingStartAnswerId.No])?.total },
        { label: 'Total check-in views', value: stats?.views },
        { label: 'Total vote without checkin' },
        { label: 'Total checkin when card close' },
        { label: 'Total vote when card close' },
      ];
    case ActivityTypes.Poll:
      return [
        { label: 'Total votes', value: sumBy(stats?.votes, 'total') },
        { label: 'Total card views', value: stats?.views },
        { label: 'Total vote when card close' },
      ];
    case ActivityTypes.Info:
      return [{ label: 'Total card views', value: stats?.views }];
    default:
      return [];
  }
}

const General: React.FC<{ type: 'program' | 'activity' }> = (props) => {
  const { isMobile, program, activity } = useStatsContext();
  const query = useGetProgramStatsQuery({ id: program.id });
  const isPending = query.status === QueryStatus.pending;
  const data = query.data?.getProgramStats;
  const hasData = data !== undefined;

  if ((isPending && !hasData) || !hasData) {
    return (
      <Spin
        size="large"
        className="fullscreen-spin"
        tip="Fetching..."
        indicator={<LoadingOutlined style={{ fontSize: 48, marginBottom: 16 }} spin />}
      />
    );
  }

  const title =
    props.type === 'activity' && activity !== undefined ? (
      <Space align="center" size={16}>
        <Badge type={activity.type as ActivityTypes} style={{ display: 'inherit' }} />
        {activity?.name}
      </Space>
    ) : (
      program.name
    );

  return (
    <>
      <div className={css.header}>
        {/* <div className={`text-${isMobile ? 'sub' : ''}heading text-center`}>Statistics</div> */}
        <div className={`text-${isMobile ? 'sub' : ''}title text-center`}>{title}</div>
      </div>
      <Affix className="text-center" offsetTop={72}>
        <Space size="middle" wrap className={css.actions}>
          <Button size="small" icon={<SyncOutlined />} shape="round" onClick={query.refetch} loading={isPending}>
            Refresh
          </Button>
          <Button size="small" icon={<FileOutlined />} shape="round" disabled>
            Export
          </Button>
        </Space>
      </Affix>
      <div className={css.sections}>
        {props.type === 'program' ? (
          <>
            {data && <Section name="Program:" data={getProgramGeneralStats(data).common()} />}
            {data?.registrations?.length ? (
              <Section name="Registration:" data={getProgramGeneralStats(data).registration()} />
            ) : null}
            {/*<Section name="Users" data={data.users} />*/}
            {/*<Section name="Votes" data={data.votes} />*/}
            {/*<Section name="Registration" data={data.registration} />*/}
            {/*{data.activities.map((activity) => (*/}
            {/*  <Section name="Statistics" data={activity} key={`program-activity-${activity.title}`} />*/}
            {/*))}*/}
          </>
        ) : (
          activity && data && <Section data={getActivityGeneralStats(data, activity)} />
        )}
      </div>
    </>
  );
};

export default General;
