import React, { ReactNode, memo } from 'react';
import cn from 'classnames';
import { Checkbox as AntCheckbox, CheckboxProps } from 'antd';
import Error from 'common/components/Error';
import css from './Checkbox.module.css';

export interface CheckboxPropsI extends CheckboxProps {
  label?: ReactNode;
  error?: string;
  touched?: boolean;
  name: string;
}

const Checkbox: React.FC<CheckboxPropsI> = ({ className, label, touched, error, ...props }) => (
  <div className={cn(css.checkbox, className, { [css.checked]: props.checked })}>
    <AntCheckbox {...props}>{label}</AntCheckbox>
    {error ? <Error>{error}</Error> : null}
  </div>
);

export default memo(Checkbox);
