import React, { memo, ReactNode } from 'react';
import cn from 'classnames';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import Error from 'common/components/Error';
import { isHebrew } from 'common/utils';
import css from './TextArea.module.css';

export interface TextAreaPropsI extends TextAreaProps {
  id?: string;
  label?: ReactNode;
  labelHint?: ReactNode;
  error?: string;
  touched?: boolean;
}

const TextArea = ({ label, labelHint, touched, error, className, ...props }: TextAreaPropsI) => {
  const id = props.id || props.name;

  return (
    <div className={cn(css.field, className, { [css.withCount]: props.showCount })}>
      {(label || labelHint) && (
        <label htmlFor={id} className="text-label">
          {label}
          {labelHint ? <span className={cn(css.hint, 'text-footnote')}>{labelHint}</span> : null}
        </label>
      )}
      <Input.TextArea
        {...props}
        className={cn({ 'text-hebrew': isHebrew((props.value as string) || props.placeholder) })}
        id={id}
      />
      {error ? <Error>{error}</Error> : null}
    </div>
  );
};

export default memo(TextArea);
