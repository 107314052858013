import React from 'react';
import * as y from 'yup';
import { Row, Col } from 'antd';
import { useFormik } from 'formik';
import { generatePath, useNavigate } from 'react-router-dom';
import { Activity, ActivityTypes } from 'app/api/generated';
import Button from 'common/components/Button';
import Select from 'common/components/Select';
import { ACTIVITY_EDIT_ROUTE } from 'common/constants/routes';
import InputText from 'common/components/InputText';
import { MAX_ACTIVITY_NAME_LEN } from 'features/program/validations';

type CreateActivityModalFields = Pick<Activity, 'name' | 'type'>;

const typeOptions = [
  { value: ActivityTypes.RisingStar, label: 'Rising Star' },
  { value: ActivityTypes.Info, label: 'Info' },
  { value: ActivityTypes.Poll, label: 'Poll' },
  { value: ActivityTypes.YesNo, label: 'Your voice' },
];

const ValidationSchema: y.SchemaOf<CreateActivityModalFields> = y.object().shape({
  type: y.mixed<ActivityTypes>().oneOf(Object.values(ActivityTypes)).required(),
  name: y
    .string()
    .min(2, 'Min 2 characters')
    .max(MAX_ACTIVITY_NAME_LEN, `Max ${MAX_ACTIVITY_NAME_LEN} characters`)
    .required('Required'),
});

const CreateActivity = ({
  programId,
  ...props
}: {
  handleClose: () => void;
  onSubmit: (values: CreateActivityModalFields) => void;
  programId: string;
}) => {
  const navigate = useNavigate();
  const form = useFormik<CreateActivityModalFields>({
    initialValues: {
      name: '',
      type: ActivityTypes.YesNo,
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      navigate(generatePath(ACTIVITY_EDIT_ROUTE, { programId, activityId: values.type }));
      // store new activity in program context
      props.onSubmit(values);
      // close modal
      props.handleClose();
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Row justify="end">
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          size="large"
          loading={form.isSubmitting || form.isValidating}
        >
          Continue
        </Button>
      </Row>
      <Row align="middle" style={{ minHeight: '55vh' }} justify="center" gutter={16}>
        <Col span={20}>
          <InputText
            {...form.getFieldProps('name')}
            touched={form.touched.name}
            error={form.errors.name}
            label="Activity name"
            maxLength={MAX_ACTIVITY_NAME_LEN}
            showCount
            placeholder="Type your Activity name"
            autoComplete="off"
            preset="text-hero"
          />
          <div style={{ width: 200, paddingTop: '1.6rem', margin: '0 auto' }}>
            <Select
              {...form.getFieldProps('type')}
              defaultActiveFirstOption
              options={typeOptions}
              touched={form.touched.type}
              error={form.errors.type}
              onChange={form.setFieldValue}
            />
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default CreateActivity;
