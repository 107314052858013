import React, { useState } from 'react';
import * as y from 'yup';
import cn from 'classnames';
import { get, set } from 'lodash';
import { FormikProps } from 'formik';
import { CSS } from '@dnd-kit/utilities';
import { nanoid, createNextState } from '@reduxjs/toolkit';
import { DeleteOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { PollActivity, PollActivityAnswer, PollActivityInput, PollAnswerType, ActivityTypes } from 'app/api/generated';
import Select from 'common/components/Select';
import Button from 'common/components/Button';
import Upload from 'common/components/Upload';
import InputText from 'common/components/InputText';
import ErrorComponent from 'common/components/Error';
import { MAX_ACTIVITY_NAME_LEN } from 'features/program/validations';
import programCss from 'features/program/Program.module.css';
import css from 'features/program/Lineup/Activities/Poll.module.css';

const addId = (arr: PollActivityAnswer[]) => arr.map((answer) => ({ ...answer, id: nanoid(4) }));
const dropId = (arr: (PollActivityAnswer & { id: string })[]) => arr.map(({ id, ...data }) => data);

const numOfVotesOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

const answerTypeOptions = [
  { value: PollAnswerType.Images, label: 'Images' },
  { value: PollAnswerType.TextAndImages, label: 'Text and Images' },
];

type PollProps = {
  form: FormikProps<Partial<PollActivity>>;
};

const Poll: React.FC<PollProps> = ({ form }) => {
  const sensors = [useSensor(PointerSensor)];
  const formAnswers = form.values.answers ?? [];
  const [orderedAnswers, setOrderedAnswers] = useState(addId(formAnswers));

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const oldIndex = orderedAnswers.findIndex((item) => item.id === active.id);
      const newIndex = orderedAnswers.findIndex((item) => item.id === over?.id);
      const nextFormAnswers = arrayMove(formAnswers, oldIndex, newIndex);
      const nextOrderedAnswers = arrayMove(orderedAnswers, oldIndex, newIndex);
      form.setFieldValue('answers', nextFormAnswers, true);
      setOrderedAnswers(nextOrderedAnswers);
    }
  };

  const addAnswer = () => {
    setOrderedAnswers((state) => {
      const newAnswer = { id: `a-${state.length}`, image: '', xmlAnswerId: '' };
      const nextAnswers = formAnswers
        .map((answer, index) => ({ ...answer, id: orderedAnswers[index].id }))
        .concat(newAnswer);

      form.setFormikState((state) =>
        createNextState(state, (draft) => {
          set(draft.values, 'answers', dropId(nextAnswers));
        })
      );

      return nextAnswers;
    });
  };

  const removeAnswer = (index: number) => {
    setOrderedAnswers((state) => {
      const answers = formAnswers.map((answer, index) => ({ ...answer, id: orderedAnswers[index].id }));
      const nextAnswers = createNextState(answers, (draft) => {
        draft.splice(index, 1);
      });

      form.setFormikState((state) => {
        return createNextState(state, (draft) => {
          set(draft.values, 'answers', dropId(nextAnswers));
          delete draft.errors.answers;
          delete draft.touched.answers;
        });
      });

      return nextAnswers;
    });
  };

  return (
    <div className={css.poll}>
      <div className={css.container}>
        <InputText
          {...form.getFieldProps('name')}
          touched={form.touched.name}
          error={form.errors.name}
          label="Activity name"
          maxLength={MAX_ACTIVITY_NAME_LEN}
          showCount
          placeholder="Type your Activity name"
          autoComplete="off"
          className={programCss.field}
        />
        <InputText
          {...form.getFieldProps('xmlVoteId')}
          touched={form.touched.xmlVoteId}
          error={form.errors.xmlVoteId}
          label="XML vote ID"
          placeholder="XML_name_mako"
          autoComplete="off"
          className={programCss.field}
        />
        <InputText
          {...form.getFieldProps('question')}
          touched={form.touched.question}
          error={form.errors.question}
          label="Question"
          placeholder="בחרו את המועמד שלכם לחצי הגמר"
          autoComplete="off"
          className={programCss.field}
        />
        <Select
          {...form.getFieldProps('numOfVotesAllowed')}
          defaultActiveFirstOption
          label="Votes per user"
          options={numOfVotesOptions}
          touched={form.touched.numOfVotesAllowed}
          error={form.errors.numOfVotesAllowed}
          onChange={form.setFieldValue}
          className={programCss.field}
        />
        <Select
          {...form.getFieldProps('answerType')}
          defaultActiveFirstOption
          label="Answers type"
          options={answerTypeOptions}
          touched={form.touched.answerType}
          error={form.errors.answerType}
          onChange={form.setFieldValue}
          className={programCss.field}
        />
        <InputText
          {...form.getFieldProps('thankYou')}
          touched={form.touched.thankYou}
          error={form.errors.thankYou}
          label="Thank you"
          placeholder="תודה על השתתפותכם"
          autoComplete="off"
          className={programCss.field}
        />
        <InputText
          {...form.getFieldProps('continue')}
          touched={form.touched.continue}
          error={form.errors.continue}
          label="Continue"
          placeholder="קיבלנו את זה!"
          autoComplete="off"
          className={programCss.field}
        />
      </div>
      {/* Answers */}
      <ul className={css.pollAnswers}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={orderedAnswers.map((a) => a.id)} strategy={verticalListSortingStrategy}>
            {orderedAnswers.map((answer, index) => (
              <Answer
                key={`answer-${answer.id}`}
                id={answer.id}
                form={form}
                index={index}
                type={form.values.answerType as PollAnswerType}
                removeAnswer={removeAnswer}
              />
            ))}
          </SortableContext>
        </DndContext>
      </ul>
      <Button type="primary" shape="round" size="large" icon={<PlusOutlined width="18px" />} onClick={addAnswer}>
        Answer
      </Button>
    </div>
  );
};

const Answer: React.FC<{
  form: PollProps['form'];
  type: PollAnswerType;
  removeAnswer: (index: number) => void;
  index: number;
  id: string;
}> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.id,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform ? { ...transform, scaleX: 1, scaleY: 1 } : null),
    zIndex: isDragging ? 1 : undefined,
    position: isDragging ? ('relative' as const) : undefined,
  };

  const errors = [
    get(props.form.errors, ['answers', props.index, 'xmlAnswerId']),
    props.type === PollAnswerType.TextAndImages && get(props.form.errors, ['answers', props.index, 'text']),
    get(props.form.errors, ['answers', props.index, 'image']),
  ];

  return (
    <li
      className={cn(css.pollAnswer, {
        [css.dragging]: isDragging,
        [css.images]: props.type === PollAnswerType.Images,
      })}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className={css.pollAnswerInner}>
        <div className={css.pollAnswerContent}>
          <div className={css.pollAnswerHandle} {...listeners}>
            <MoreOutlined />
          </div>
          <div className={css.pollAnswerFields}>
            <InputText
              {...props.form.getFieldProps(`answers.${props.index}.xmlAnswerId`)}
              label="XML Answer vote ID"
              placeholder="Type XML Answer vote ID"
              autoComplete="off"
              className={css.answerField}
            />
            {props.type === PollAnswerType.TextAndImages && (
              <InputText
                {...props.form.getFieldProps(`answers.${props.index}.text`)}
                label="Answer text"
                placeholder="Type Answer Text"
                autoComplete="off"
                className={css.answerField}
              />
            )}
            <Upload
              {...props.form.getFieldProps(`answers.${props.index}.image`)}
              accept="image/png, image/jpeg, image/gif"
              defaultPlaceholder={null}
              limit={300}
              placeholder={
                <p>
                  Answer image <br />
                  GIF, JPG, PNG | 300KB
                </p>
              }
              onFileChange={props.form.setFieldValue}
              className={cn(css.answerField, css.answerImage)}
              preset="preset1"
            />
          </div>
        </div>
        <Button
          className={css.remove}
          shape="circle"
          type="text"
          danger
          onClick={() => props.removeAnswer(props.index)}
        >
          <DeleteOutlined />
        </Button>
      </div>
      <div className={css.pollAnswerErrors}>
        {errors.map((error) => error && <ErrorComponent key={error}>{error}</ErrorComponent>)}
      </div>
    </li>
  );
};

export const ValidationSchema: y.SchemaOf<PollActivityInput> = y.object().shape({
  answerType: y.mixed<PollAnswerType>().oneOf(Object.values(PollAnswerType)).required(),
  answers: y
    .array()
    .of(
      y.object({
        image: y.string().required('Image is required'),
        text: y.string().optional().nullable(),
        xmlAnswerId: y.string().required('XML Answer vote ID is required'),
      })
    )
    .required(),
  name: y.string().required(),
  numOfVotesAllowed: y.number().optional().nullable(),
  question: y.string().required(),
  xmlVoteId: y.string().optional().nullable(),
  thankYou: y.string().optional().nullable().default(''),
  continue: y.string().optional().nullable().default(''),
});

export const initialValues: Partial<PollActivity> = {
  type: ActivityTypes.Poll,
  numOfVotesAllowed: 1,
  answerType: PollAnswerType.TextAndImages,
  answers: [
    { xmlAnswerId: '', image: '' },
    { xmlAnswerId: '', image: '' },
  ],
  thankYou: '',
  continue: '',
};

export default Poll;
