import React from 'react';

const Edit = ({ width = '15px' }) => (
  <svg width={width} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" className="anticon">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1141.000000, -461.000000)">
        <g transform="translate(1128.000000, 448.000000)">
          <g transform="translate(9.918611, 9.620000)">
            <polygon points="0 0 23.76 0 23.76 23.76 0 23.76" />
            <path
              d="M19.5129,7.96036154 L7.6725,19.8007615 L3.96,19.8007615 L3.96,16.0882615 L15.8004,4.24786154 C15.9786,4.06966154 16.207413,3.97370769 16.4401193,3.96 L16.5565807,3.96 C16.7505026,3.97142308 16.9417208,4.03996154 17.1031985,4.16561538 L17.1963,4.24786154 L19.5129,6.56446154 C19.8693,6.92086154 19.8967154,7.47971361 19.5951462,7.86726008 L19.5129,7.96036154 Z M14.3978675,7.05124406 L4.95,16.4981215 L4.95,18.8107615 L7.26066,18.8107615 L16.7095175,9.36289406 L14.3978675,7.05124406 Z M16.49835,4.95076154 L15.0979032,6.35120834 L17.4095532,8.66285834 L18.81,7.26241154 L16.49835,4.95076154 Z"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const Lineup = ({ width = '15px', styles = {} }) => (
  <svg width={width} viewBox="0 0 17 15" xmlns="http://www.w3.org/2000/svg" style={styles} className="anticon">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1198.000000, -462.000000)">
        <g transform="translate(1185.000000, 448.000000)">
          <g transform="translate(9.703333, 9.703333)">
            <polygon points="0 0 23.76 0 23.76 23.76 0 23.76" />
            <g opacity="0.5" transform="translate(5.940000, 6.930000)" fill="#FFFFFF">
              <path d="M4.95,0 L13.86,0 L13.86,1.98 L4.95,1.98 L4.95,0 Z M4.95,7.92 L13.86,7.92 L13.86,9.9 L4.95,9.9 L4.95,7.92 Z M0.99,0 C1.5367619,-1.00438532e-16 1.98,0.443238098 1.98,0.99 C1.98,1.5367619 1.5367619,1.98 0.99,1.98 C0.443238098,1.98 -3.77130188e-16,1.5367619 0,0.99 C-6.69590214e-17,0.443238098 0.443238098,1.00438532e-16 0.99,0 Z" />
            </g>
            <path
              d="M6.93,12.87 C8.57028571,12.87 9.9,14.1997143 9.9,15.84 C9.9,17.4802857 8.57028571,18.81 6.93,18.81 C5.28971429,18.81 3.96,17.4802857 3.96,15.84 C3.96,14.1997143 5.28971429,12.87 6.93,12.87 Z M6.93,13.86 C5.8364762,13.86 4.95,14.7464762 4.95,15.84 C4.95,16.9335238 5.8364762,17.82 6.93,17.82 C8.0235238,17.82 8.91,16.9335238 8.91,15.84 C8.91,14.7464762 8.0235238,13.86 6.93,13.86 Z M6.93,4.95 C8.57028571,4.95 9.9,6.27971429 9.9,7.92 C9.9,9.56028571 8.57028571,10.89 6.93,10.89 C5.28971429,10.89 3.96,9.56028571 3.96,7.92 C3.96,6.27971429 5.28971429,4.95 6.93,4.95 Z M6.93,5.94 C5.8364762,5.94 4.95,6.8264762 4.95,7.92 C4.95,9.0135238 5.8364762,9.9 6.93,9.9 C8.0235238,9.9 8.91,9.0135238 8.91,7.92 C8.91,6.8264762 8.0235238,5.94 6.93,5.94 Z"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const Duplicate = ({ width = '15px' }) => (
  <svg width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="anticon">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1255.000000, -460.000000)">
        <g transform="translate(1242.000000, 448.000000)">
          <g transform="translate(9.203333, 8.703333)">
            <polygon points="0 0 23.76 0 23.76 23.76 0 23.76" />
            <g opacity="0.5" transform="translate(8.415000, 8.167500)" fill="#FFFFFF">
              <polygon points="4.23814109e-13 0 9.9 0 9.9 9.9 -8.79296636e-15 9.9" />
            </g>
            <path
              d="M18.315,7.1775 C18.8617619,7.1775 19.305,7.58664286 19.305,8.09134615 L19.305,18.1436538 C19.305,18.6483571 18.8617619,19.0575 18.315,19.0575 L8.415,19.0575 C7.8682381,19.0575 7.425,18.6483571 7.425,18.1436538 L7.425,8.09134615 C7.425,7.58664286 7.8682381,7.1775 8.415,7.1775 L18.315,7.1775 Z M18.315,8.1675 L8.415,8.1675 L8.415,18.0675 L18.315,18.0675 L18.315,8.1675 Z"
              fill="#FFFFFF"
            />
            <path
              d="M14.85,3.96 C15.3967619,3.96 15.84,4.36914286 15.84,4.87384615 L15.84,4.95 L4.95,4.95 L4.95,15.84 C4.4032381,15.84 3.96,15.4308571 3.96,14.9261538 L3.96,4.87384615 C3.96,4.36914286 4.4032381,3.96 4.95,3.96 L14.85,3.96 Z"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const Archive = ({ width = '15px' }) => (
  <svg width={width} viewBox="0 0 17 15" xmlns="http://www.w3.org/2000/svg" className="anticon">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1312.000000, -462.000000)">
        <g transform="translate(1299.000000, 448.000000)">
          <g transform="translate(9.620000, 9.620000)">
            <polygon points="0 0 23.76 0 23.76 23.76 0 23.76" />
            <g opacity="0.5" />
            <path
              d="M18.93375,5.1975 C19.4121667,5.1975 19.8,5.6407381 19.8,6.1875 L19.8,7.92 C19.8,8.4667619 19.4121667,8.91 18.93375,8.91 L18.81,8.9095 L18.81,17.5725 C18.81,18.1192619 18.4221667,18.5625 17.94375,18.5625 L5.81625,18.5625 C5.33783334,18.5625 4.95,18.1192619 4.95,17.5725 L4.95,8.9095 L4.82625,8.91 C4.34783334,8.91 3.96,8.4667619 3.96,7.92 L3.96,6.1875 C3.96,5.6407381 4.34783334,5.1975 4.82625,5.1975 L18.93375,5.1975 Z M17.82,9.1575 L5.94,9.1575 L5.94,17.5725 L17.82,17.5725 L17.82,9.1575 Z M14.85,10.89 L14.85,11.88 L8.91,11.88 L8.91,10.89 L14.85,10.89 Z M18.81,6.1875 L4.95,6.1875 L4.95,8.1675 L18.81,8.1675 L18.81,6.1875 Z"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const LongArrowLeft = ({ width = '23px', style }: { width?: string; style?: React.CSSProperties }) => (
  <svg width={width} viewBox="0 0 23 8" xmlns="http://www.w3.org/2000/svg" style={style} className="anticon">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-287.000000, -46.000000)">
        <g transform="translate(281.500000, 25.000000)">
          <g transform="translate(0.868000, 8.368000)">
            <polygon
              fill="currentColor"
              points="8.98635341 13.167 8.98635341 15.8803126 27.4110948 15.8803126 27.4110948 17.2663126 8.98635341 17.2663126 8.98635341 20.097 5.1975 16.8287799"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const Eye = ({ width = '19px', style }: { width?: string; style?: React.CSSProperties }) => (
  <svg
    width={width}
    viewBox="0 0 19 14"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    className="anticon"
    fill="none"
  >
    <path
      d="M9.49684 4.83831C8.92434 4.83831 8.38551 5.0611 7.97928 5.46675C7.57306 5.87239 7.34995 6.41045 7.34995 6.98214C7.34995 7.55382 7.57306 8.09188 7.97928 8.49752C8.38551 8.90317 8.92434 9.12596 9.49684 9.12596C10.0693 9.12596 10.6082 8.90317 11.0144 8.49752C11.4206 8.09188 11.6437 7.55382 11.6437 6.98214C11.6437 6.41045 11.4206 5.87239 11.0144 5.46675C10.6103 5.0611 10.0693 4.83831 9.49684 4.83831ZM19 6.99264C18.9937 6.87494 18.8127 6.29275 18.5601 5.68743C18.3012 5.0632 17.8634 4.15944 17.2572 3.40489C16.4637 2.41285 15.485 1.62048 14.3484 1.053C12.9529 0.3531 11.3196 0 9.49684 0C7.67619 0 6.04287 0.355202 4.64739 1.0551C3.51922 1.61838 2.54049 2.40865 1.73856 3.40489C1.13238 4.16154 0.696687 5.06531 0.437797 5.68954C0.185222 6.29485 0.00631439 6.87705 0 6.99475C0 6.99685 0 6.99685 0 6.99895C0 7.00105 0 7.00105 0 7.00315C0.00631439 7.12085 0.187327 7.70305 0.439902 8.31047C0.700897 8.9347 1.13659 9.83846 1.74067 10.5909C2.53418 11.5829 3.51291 12.3753 4.6495 12.9428C6.05339 13.6448 7.68461 14 9.49895 14C11.3154 14 12.9466 13.6448 14.3484 12.9428C15.4808 12.3774 16.4595 11.585 17.2572 10.593C17.8634 9.83846 18.3012 8.9347 18.5601 8.31047C18.8127 7.70515 18.9937 7.12295 19 7.00525C19 7.00315 19 7.00315 19 7.00105C19 6.99685 19 6.99475 19 6.99264ZM9.49684 10.6245C7.48466 10.6245 5.84923 8.98934 5.84923 6.98214C5.84923 4.97493 7.48676 3.33974 9.49684 3.33974C11.5069 3.33974 13.1445 4.97493 13.1445 6.98214C13.1445 8.98934 11.509 10.6245 9.49684 10.6245Z"
      fill="white"
    />
  </svg>
);

const EyeCancelled = ({ width = '19px', style }: { width?: string; style?: React.CSSProperties }) => (
  <svg
    width={width}
    viewBox="0 0 19 16"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    className="anticon"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6849 15.2485L10.3144 12.5501C10.0554 12.6084 9.7861 12.6393 9.50965 12.6393C7.50017 12.6393 5.86695 11.0112 5.86695 9.01276C5.86695 9.00502 5.86697 8.99729 5.86702 8.98956L1.64104 5.60625C1.09827 6.32521 0.70382 7.14643 0.462808 7.7258C0.210573 8.32847 0.0319067 8.90812 0.0256008 9.02531V9.0295V9.03368C0.0319067 9.15087 0.212675 9.73052 0.46491 10.3353C0.725554 10.9568 1.16066 11.8566 1.76392 12.6058C2.55636 13.5935 3.53377 14.3824 4.66883 14.9474C6.07084 15.6463 7.69986 16 9.51175 16C11.0435 16 12.4434 15.7478 13.6849 15.2485Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 9.02322C18.9937 8.90603 18.8129 8.32638 18.5607 7.7237C18.3021 7.1022 17.8649 6.20237 17.2596 5.45112C16.4671 4.46341 15.4897 3.67449 14.3547 3.10949C12.9611 2.41265 11.3299 2.06109 9.50965 2.06109C7.81258 2.06109 6.27849 2.36919 4.9484 2.97688L1.23008 0L0 1.52282L17.3924 15.4471L18.6225 13.9243L17.1478 12.7437C17.1855 12.6989 17.2227 12.6536 17.2596 12.6079C17.8649 11.8566 18.3021 10.9568 18.5607 10.3353C18.8129 9.73262 18.9937 9.15296 19 9.03577V9.03159V9.02322ZM13.1523 9.01276C13.1523 7.01431 11.517 5.38625 9.50965 5.38625C9.06395 5.38625 8.6366 5.46651 8.24141 5.61326L9.85592 6.90584C10.2968 6.97674 10.7042 7.18279 11.0252 7.50398C11.2354 7.7133 11.3965 7.95803 11.503 8.22449L13.1173 9.51686C13.1404 9.35206 13.1523 9.18376 13.1523 9.01276Z"
      fill="white"
    />
  </svg>
);

const Marker = ({ width = '13px', style }: { width?: string; style?: React.CSSProperties }) => (
  <svg width={width} viewBox="0 0 13 17" xmlns="http://www.w3.org/2000/svg" style={style} className="anticon">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-838.000000, -377.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g transform="translate(336.000000, 350.000000)">
          <path d="M508.738685,27 C505.424977,27 502.738685,29.7903658 502.738685,33.2324565 C502.738685,36.5243268 508.08414,43.4876805 508.313231,43.7879716 C508.416756,43.9220098 508.573251,44 508.738685,44 C508.90412,44 509.060614,43.9220098 509.16414,43.7879716 C509.393231,43.4876805 514.738685,36.5243268 514.738685,33.2324565 C514.738685,29.7903658 512.052394,27 508.738685,27 L508.738685,27 Z M508.738685,34.9322174 C507.5337,34.9322174 506.556867,33.9175389 506.556867,32.6658696 C506.556867,31.4142002 507.5337,30.3995217 508.738685,30.3995217 C509.94367,30.3995217 510.920503,31.4142002 510.920503,32.6658696 C510.920503,33.9175389 509.94367,34.9322174 508.738685,34.9322174 L508.738685,34.9322174 Z" />
        </g>
      </g>
    </g>
  </svg>
);

const MarkerCancelled = ({ width = '22px', style }: { width?: string; style?: React.CSSProperties }) => (
  <svg width={width} viewBox="0 0 472 424" xmlns="http://www.w3.org/2000/svg" style={style} className="anticon">
    <g stroke="none" strokeWidth="1" fill="#FFFFFF" fillRule="evenodd">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M290.937 347.638L76.0236 175.816C93.986 262.02 209.102 407.666 216.977 417.629L217.205 417.918C219.871 421.253 223.9 423.194 228.16 423.194C232.419 423.194 236.449 421.253 239.115 417.918L239.343 417.629C242.651 413.444 264.909 385.283 290.937 347.638Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.722355 44.724L29.1249 9.20176C29.8069 8.34878 31.0513 8.21017 31.9043 8.89215L102.369 65.2282C130.385 25.8583 176.287 0.193848 228.16 0.193848C313.48 0.193848 382.646 69.6247 382.646 155.272C382.646 182.991 366.884 221.173 345.799 259.85L470.547 359.586C471.4 360.267 471.538 361.512 470.856 362.365L442.454 397.887C441.772 398.74 440.527 398.879 439.674 398.197L1.03199 47.5033C0.178977 46.8213 0.0403431 45.577 0.722355 44.724ZM284.337 141.174C284.337 110.03 259.185 84.782 228.16 84.782C203.391 84.782 182.366 100.874 174.893 123.212L257.513 189.265C273.604 179.347 284.337 161.519 284.337 141.174Z"
      />
    </g>
  </svg>
);

const Play = ({ width = '12px', style }: { width?: string; style?: React.CSSProperties }) => (
  <svg width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" style={style} className="anticon">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-863.000000, -380.000000)" fill="#FFFFFF">
        <g transform="translate(336.000000, 350.000000)">
          <g transform="translate(514.000000, 18.000000)">
            <path
              d="M20.1410038,13.1833917 L25.4170527,22.9237896 C25.6088117,23.2778062 25.4772759,23.7202448 25.1232593,23.9120038 C25.0166417,23.969755 24.8973027,24 24.7760488,24 L14.2239512,24 C13.8213356,24 13.4949512,23.6736156 13.4949512,23.271 C13.4949512,23.1497461 13.5251961,23.0304072 13.5829473,22.9237896 L18.8589962,13.1833917 C19.0507552,12.8293751 19.4931938,12.6978393 19.8472104,12.8895983 C19.9715666,12.9569579 20.0736443,13.0590356 20.1410038,13.1833917 Z"
              transform="translate(19.500000, 18.000000) rotate(90.000000) translate(-19.500000, -18.000000) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const Stop = ({ width = '13px', style }: { width?: string; style?: React.CSSProperties }) => (
  <svg width={width} viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg" style={style} className="anticon">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-862.000000, -379.000000)" fill="#FFFFFF">
        <g transform="translate(336.000000, 350.000000)">
          <rect x="526" y="29" width="12.9849135" height="13.0232558" rx="1.458" />
        </g>
      </g>
    </g>
  </svg>
);

const User = ({ width = '38px', style }: { width?: string; style?: React.CSSProperties }) => (
  <svg width={width} viewBox="0 0 38 39" xmlns="http://www.w3.org/2000/svg" style={style} className="anticon">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-858.000000, -61.000000)">
        <g transform="translate(851.000000, 52.000000)">
          <g transform="translate(0.000000, 5.000000)">
            <g transform="translate(-2.108080, -4.608080)">
              <path
                d="M28.10808,28.10808 C33.2846514,28.10808 37.47744,23.9152914 37.47744,18.73872 C37.47744,13.5621486 33.2846514,9.36936 28.10808,9.36936 C22.9315086,9.36936 18.73872,13.5621486 18.73872,18.73872 C18.73872,23.9152914 22.9315086,28.10808 28.10808,28.10808 Z M28.10808,32.79276 C21.8540322,32.79276 9.36936,35.9314956 9.36936,42.16212 L9.36936,46.8468 L46.8468,46.8468 L46.8468,42.16212 C46.8468,35.9314956 34.3621278,32.79276 28.10808,32.79276 Z"
                fillOpacity="0.12"
                fill="#000000"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const Icon = {
  Edit,
  Lineup,
  Duplicate,
  Archive,
  LongArrowLeft,
  Eye,
  EyeCancelled,
  Marker,
  MarkerCancelled,
  Play,
  Stop,
  User,
};

export default Icon;
