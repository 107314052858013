import { QueryStatus } from '@reduxjs/toolkit/query';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthStatus, MutationLoginArgs } from 'app/api/generated';
import { RootState } from 'app/store';
import { api } from 'app/api';

export interface UserState {
  isLoggedIn: boolean;
  status: QueryStatus;
  email: string | null;
  ui: {
    isAsideCollapsed: boolean;
  };
}

const initialState: UserState = {
  isLoggedIn: false,
  status: QueryStatus.uninitialized,
  email: null,
  ui: {
    isAsideCollapsed: false,
  },
};

export const userLogin = createAsyncThunk(
  'user/login',
  async (payload: MutationLoginArgs, { dispatch, rejectWithValue }) => {
    const response = await dispatch(api.endpoints.Login.initiate(payload)).unwrap();
    return response.login.failed ? rejectWithValue(response.login.failed.reason) : response.login.status;
  }
);

export const userGetMe = createAsyncThunk('user/getMe', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await dispatch(api.endpoints.GetMe.initiate()).unwrap();
    return response.getMe.email ? response.getMe.email : rejectWithValue(null);
  } catch (e) {
    return null;
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
    },
    setIsAsideCollapsed: (state, action) => {
      state.ui.isAsideCollapsed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userGetMe.fulfilled, (state, action) => {
        state.email = action.payload;
        state.isLoggedIn = action.payload !== null;
      })
      .addCase(userLogin.pending, (state) => {
        state.status = QueryStatus.pending;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.status = QueryStatus.fulfilled;
        state.isLoggedIn = action.payload === AuthStatus.Authenticated;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.status = QueryStatus.rejected;
      });
    // .addMatcher(api.endpoints.Login.matchFulfilled, (state, { meta }) => {
    //   console.info('login', { meta });
    // })
    // .addMatcher(api.endpoints.GetMe.matchFulfilled, (state, { meta }) => {
    //   console.info('me', { meta });
    // });
  },
});

export const { logout, setIsAsideCollapsed } = userSlice.actions;

export const selectIsAsideCollapsed = (state: RootState) => state.user.ui.isAsideCollapsed;

export const selectIsLoggedIn = (state: RootState) => state.user.isLoggedIn;

// todo: how to get status from `api.endpoints.Login.select()`
export const selectLoginStatus = (state: RootState) => state.user.status;

export const selectGetMeStatus = (state: RootState) => api.endpoints.GetMe.select()(state).status;

export default userSlice.reducer;
