import React from 'react';
import cn from 'classnames';
import { Row, Col } from 'antd';
import { get, set } from 'lodash';
import { FieldInputProps, FormikProps } from 'formik';
import { Program, RegistrationFieldType } from 'app/api/generated';
import Checkbox from 'common/components/Checkbox';
import InputText from 'common/components/InputText';
import css from './Program.module.css';
import { createNextState } from '@reduxjs/toolkit';

interface Props {
  form: FormikProps<Program>;
}

const getCheckboxFieldProps = ({ value: checked, ...props }: FieldInputProps<boolean>) => ({
  ...props,
  checked,
});

const col = ['auto', '100px', '100px'];

export const fields = [
  { label: 'Full name', name: RegistrationFieldType.FullName },
  { label: 'Email', name: RegistrationFieldType.Email },
  { label: 'Phone', name: RegistrationFieldType.Phone },
  { label: 'Custom field', name: RegistrationFieldType.CustomTextFieldOne, labelField: true },
  { label: 'Custom checkbox 1', name: RegistrationFieldType.CheckboxOne, labelField: true },
  { label: 'Custom checkbox 2', name: RegistrationFieldType.CheckboxTwo, labelField: true },
];

const RegistrationFields = (props: Props) => {
  return (
    <div className="registration-fields">
      <Row className={cn(css.regFieldsRow, 'text-label')} align="middle" justify="center">
        <Col flex={col[0]}>Choose fields</Col>
        <Col flex={col[1]} className="text-center">
          Show
        </Col>
        <Col flex={col[2]} className="text-center">
          Required
        </Col>
      </Row>
      {fields.map((field, index) => {
        const path = `registration.fields[${index}]`;
        const isActive = get(props.form.values, `${path}.active`);
        const commonFieldProps = (name: 'label' | 'required' | 'active') => ({
          touched: get(props.form.touched, `${path}.${name}`),
          error: get(props.form.errors, `${path}.${name}`),
        });

        const activeFieldProps = {
          ...getCheckboxFieldProps(props.form.getFieldProps(`${path}.active`)),
          ...commonFieldProps('active'),
        };

        const requiredFieldProps = {
          ...getCheckboxFieldProps(props.form.getFieldProps(`${path}.required`)),
          ...commonFieldProps('required'),
          ...(isActive ? {} : { disabled: true, checked: false }),
        };

        return (
          <Row key={`reg-fields-row-${path}`} className={css.regFieldsRow} align="middle" justify="center">
            <Col flex={col[0]}>{field.label}</Col>
            <Col flex={col[1]}>
              <Checkbox
                {...activeFieldProps}
                onChange={(e) => {
                  const { checked } = e.target;

                  props.form.setValues(
                    createNextState(props.form.values, (draft) => {
                      set(draft, props.form.getFieldProps(`${path}.name`).name, field.name);
                      set(draft, activeFieldProps.name, checked);
                      set(draft, requiredFieldProps.name, false);
                    })
                  );
                }}
              />
            </Col>
            <Col flex={col[2]}>
              <Checkbox {...requiredFieldProps} />
            </Col>
            {field.labelField && isActive && (
              <InputText
                className={css.regLabelField}
                {...props.form.getFieldProps(`${path}.label`)}
                {...commonFieldProps('label')}
                placeholder="Label"
              />
            )}
          </Row>
        );
      })}
    </div>
  );
};

export default RegistrationFields;
