import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { omit } from 'lodash';
import { message } from 'antd';
import { useMatch } from 'react-router-dom';
import { ListenerParameters } from 'pubnub';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor } from '@dnd-kit/core';
import * as pubnub from 'app/api/pubnub';
import {
  Activity,
  InfoActivity,
  PollActivity,
  YesNoActivity,
  Program,
  RisingStarActivity,
  ActivityStages,
  ActivityTypes,
} from 'app/api/generated';
import {
  useCreateInfoActivityMutation,
  useCreatePollActivityMutation,
  useCreateRisingStarActivityMutation,
  useCreateYesNoActivityMutation,
  useDeleteActivityMutation,
  useUpdateActivitiesOrderMutation,
} from 'app/api';
import Button from 'common/components/Button';
import { PROGRAM_LINEUP_VIEWER_ROUTE } from 'common/constants/routes';
import { MAX_ACTIVITY_NAME_LEN } from 'features/program/validations';
import LineupHistoryStorage from 'features/program/Lineup/LineupHistoryStorage';
import Item from 'features/program/Lineup/FeedItem';
import Emergency from 'features/program/Lineup/Emergency';
import css from 'features/program/Lineup/Lineup.module.css';

const offlineMessageKey = 'offlineMsg';

const useActivityStageUpdateListener = ({
  programId,
  onMessage,
}: {
  programId: string;
  onMessage: React.Dispatch<React.SetStateAction<Activity[]>>;
}) => {
  useEffect(() => {
    const listener: ListenerParameters = {
      status(statusEvent) {
        switch (statusEvent.category) {
          case 'PNNetworkDownCategory':
            message.error({ duration: 0, content: "You're offline! 😴", key: offlineMessageKey });
            break;
          case 'PNNetworkUpCategory':
            message.destroy(offlineMessageKey);
            message.success("You're online! 😎", 3);
            break;
          default:
            break;
        }
      },
      message: (event) => {
        if (event.channel !== programId) {
          return;
        }
        try {
          const message = JSON.parse(event.message);
          switch (message.type) {
            case pubnub.PNMessageTypes.ActivityStageUpdate:
              onMessage((activities) => {
                // story played activity id
                new LineupHistoryStorage({ programId, activityId: message.activity.id }).add();

                // update activities stage
                return activities.map((activity) => {
                  let stage = activity.stage;

                  if (message.activity.id === activity.id) stage = message.activity.stage;
                  else if (message.activity.stage !== ActivityStages.Hide) stage = ActivityStages.Hide;

                  return { ...activity, stage };
                });
              });
              break;
            default:
              break;
          }
        } catch (e) {
          message.error(`pubnub/listener/message: ${(e as Error).message}`);
        }
      },
    };

    pubnub.subscribeChannel(programId);
    pubnub.addListener(listener);

    return () => {
      pubnub.unsubscribeChannel(programId);
      pubnub.removeListener(listener);
    };
  }, [programId, onMessage]);

  return null;
};

const Feed = ({ program }: { program: Program }) => {
  const isViewer = useMatch(PROGRAM_LINEUP_VIEWER_ROUTE) !== null;
  const [forceShowButtons, setForceShowButtons] = useState(false);
  const [createRisingStar] = useCreateRisingStarActivityMutation();
  const [createInfo] = useCreateInfoActivityMutation();
  const [createPoll] = useCreatePollActivityMutation();
  const [createYesNo] = useCreateYesNoActivityMutation();
  const [updateOrder] = useUpdateActivitiesOrderMutation();
  const [deleteActivity] = useDeleteActivityMutation();
  const [activities, setActivities] = useState(program.activities || []);
  const sensors = [useSensor(PointerSensor)];

  // set activity stages on pubnub message receive
  useActivityStageUpdateListener({ programId: program.id, onMessage: setActivities });

  useEffect(() => {
    setActivities((prevActivities) => {
      const prevStagesMap = prevActivities.reduce<Record<string, Activity['stage']>>((memo, item) => {
        memo[item.id] = item.stage;
        return memo;
      }, {});

      // preserve prev stages, in case of backend were offline and didn't get latest stages, but trust, that pubnub
      // weren't offline and latest stages are set here as result of pubnub received message
      return (program.activities || []).map((activity) => ({
        ...activity,
        stage: prevStagesMap[activity.id] ?? activity.stage,
      }));
    });
  }, [program]);

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const order: string[] = [];

      setActivities((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);
        const nextActivities = arrayMove(items, oldIndex, newIndex);

        nextActivities.forEach(({ id }) => {
          order.push(id);
        });

        return nextActivities;
      });

      updateOrder({ id: program.id, order })
        .unwrap()
        .then((result) => {
          if (result.updateActivitiesOrder) message.success('Order updated');
        })
        .catch((reason) => {
          message.error(typeof reason === 'string' ? reason : 'Update order failed.');
        });
    }
  };

  const handleDuplicate = useCallback(
    ({ programId, activity }: { programId: string; activity: Activity }) => {
      const onOk = () => {
        message.destroy();
        message.success('Duplicated successfully.');
      };
      const onErr = (reason: unknown) => message.error(typeof reason === 'string' ? reason : 'Duplicate failed.');
      const onStart = () => message.info('Duplicating...');

      const getPayload = <T extends Activity>() => ({
        programId,
        activity: {
          ...omit(activity as T, ['id', 'type', 'stage']),
          name: `Duplicated - ${activity.name}`.slice(0, MAX_ACTIVITY_NAME_LEN),
        },
      });

      onStart();

      switch (activity.type) {
        case ActivityTypes.RisingStar:
          createRisingStar(getPayload<RisingStarActivity>()).unwrap().then(onOk).catch(onErr);
          break;
        case ActivityTypes.Info:
          createInfo(getPayload<InfoActivity>()).unwrap().then(onOk).catch(onErr);
          break;
        case ActivityTypes.Poll:
          createPoll(getPayload<PollActivity>()).unwrap().then(onOk).catch(onErr);
          break;
        case ActivityTypes.YesNo:
          createYesNo(getPayload<YesNoActivity>()).unwrap().then(onOk).catch(onErr);
          break;
        default:
          break;
      }
    },
    [createPoll, createInfo, createRisingStar, createYesNo]
  );

  const handleDelete = useCallback(
    ({ programId, activityId }: { programId: string; activityId: string }) => {
      const onOk = () => {
        message.destroy();
        message.success('Deleted successfully.');
      };
      const onErr = (reason: unknown) => message.error(typeof reason === 'string' ? reason : 'Delete failed.');

      message.info('Deleting activity...');
      deleteActivity({ activityId, programId }).unwrap().then(onOk).catch(onErr);
    },
    [deleteActivity]
  );

  return (
    <ol className={cn(css.feed, 'text-paragraph')}>
      {!isViewer && (
        <Button
          type="text"
          size="small"
          onClick={() => setForceShowButtons((v) => !v)}
          style={{
            position: 'fixed',
            right: 238,
            top: 176,
            opacity: forceShowButtons ? 1 : 0.35,
            color: forceShowButtons ? 'var(--color-danger)' : '',
            zIndex: 10,
          }}
        >
          Force all buttons
        </Button>
      )}
      {!isViewer ? <Emergency programId={program.id} /> : null}
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={activities.map((item) => item.id)} strategy={verticalListSortingStrategy}>
          {activities.map((activity, index) => (
            <Item
              key={`activity-${activity.id}`}
              item={activity}
              programId={program.id}
              onDuplicate={handleDuplicate}
              onDelete={handleDelete}
              forceShowButtons={forceShowButtons}
              isViewer={isViewer}
            />
          ))}
        </SortableContext>
      </DndContext>
    </ol>
  );
};

export default Feed;
