import React, { ReactNode, memo } from 'react';
import { FieldInputProps } from 'formik';
import cn from 'classnames';
import { Switch, SwitchProps, Space } from 'antd';
import Error from 'common/components/Error';
import css from './Toggle.module.css';

export interface TogglePropsI extends Omit<SwitchProps, 'onChange'> {
  label?: ReactNode;
  inlineValue?: [string, string] | null;
  error?: string;
  touched?: boolean;
  name: string;
  onChange?: (name: string, value: boolean) => void;
}

const Toggle = ({
  className,
  label,
  inlineValue = ['Active', 'Disabled'],
  touched,
  error,
  onChange,
  ...props
}: TogglePropsI) => {
  const value = props.checked ? inlineValue?.[0] : inlineValue?.[1];

  return (
    <div className={cn(css.toggle, className, { [css.checked]: props.checked })}>
      <label className="text-label">
        <div className={css.label}>{label}</div>
        <Space size={10}>
          <Switch {...props} onChange={(value, event) => onChange?.(props.name, value)} />
          {value ? <span className={css.inlineValue}>{value}</span> : null}
        </Space>
      </label>
      {error ? <Error>{error}</Error> : null}
    </div>
  );
};

export const getToggleFieldProps = ({ value: checked, ...props }: FieldInputProps<boolean>) => ({
  ...props,
  checked,
});

export default memo(Toggle);
