class LineupHistoryStorage {
  id = 'lv-lineup-history';

  programId: string;

  activityId: string;

  constructor(props: { programId: string; activityId: string }) {
    this.programId = props.programId;
    this.activityId = props.activityId;
  }

  add() {
    this.storage?.add();
  }

  has() {
    return this.storage?.get.indexOf(this.activityId) !== -1;
  }

  private init() {
    window.localStorage.setItem(this.id, JSON.stringify({ [this.programId]: [] }));
  }

  private get storage() {
    if (!('localStorage' in window)) return null;

    const item = window.localStorage.getItem(this.id);

    if (!item) this.init();

    let collection: string[] = [];
    let itemParsed: Record<string, string[] | undefined>;

    try {
      itemParsed = JSON.parse(item ?? '');
      if (itemParsed) {
        const _collection = itemParsed[this.programId];
        if (Array.isArray(_collection)) {
          collection = _collection;
        }
      }
    } catch (e) {
      this.init();
    }

    return {
      get: collection,
      add: () => {
        window.localStorage.setItem(
          this.id,
          JSON.stringify({
            ...itemParsed,
            [this.programId]:
              collection.indexOf(this.activityId) === -1 ? [...collection, this.activityId] : collection,
          })
        );
      },
    };
  }
}

export default LineupHistoryStorage;
