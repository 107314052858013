import React from 'react';
import { findIndex, orderBy, round } from 'lodash';
import { Affix, Space, Statistic, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { createNextState } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { FileOutlined, SyncOutlined } from '@ant-design/icons';
import cn from 'classnames';
import Button from 'common/components/Button';
import { Activity, Program, ActivityTypes } from 'app/api/generated';
import { RisingStartAnswerId } from 'features/program/types';
import { useStatsContext } from 'features/program/Stats';
import css from 'features/program/Stats/Stats.module.css';

const getAnswerCol = (type: string | null) => ({
  title: 'Answer',
  dataIndex: 'answer',
  key: 'answer',
  render: (answer: VotesData[number]['answer']) =>
    type === ActivityTypes.Poll && answer.value?.match(/\.(png|jpg|jpeg|gif|svg)/) ? (
      <>
        <img src={answer.value} alt="" style={{ height: 50, maxWidth: 100, objectFit: 'cover', margin: '16px 0' }} />
        <span style={{ marginLeft: '20px' }}>{answer.text}</span>
      </>
    ) : (
      answer.value
    ),
});

const getPercentageCol = (total: number) => ({
  title: 'Percentage',
  dataIndex: 'votes',
  align: 'right' as const,
  key: 'percentage',
  render: (votes: number) => {
    return `${round((votes / total) * 100, 2) || 0}%`;
  },
});

const votesCol = {
  title: 'Votes',
  dataIndex: 'votes',
  align: 'right' as const,
  key: 'votes',
  render(votes: number) {
    return <Statistic value={votes} />;
  },
};

export type VotesData = { answer: { value: string; text?: string }; votes: number; xmlAnswerId?: string }[];

export const getInitialValues = (): Record<ActivityTypes, VotesData> => ({
  [ActivityTypes.RisingStar]: [
    { answer: { value: 'Yes' }, votes: 0 },
    { answer: { value: 'No' }, votes: 0 },
  ],
  [ActivityTypes.YesNo]: [
    { answer: { value: 'Yes' }, votes: 0 },
    { answer: { value: 'No' }, votes: 0 },
  ],
  [ActivityTypes.Poll]: [],
  [ActivityTypes.Info]: [],
});

const VotesHeader = ({ activityName, programName }: { activityName: string; programName: string }) => {
  const { isMobile } = useStatsContext();
  return (
    <div className={css.header}>
      <div className={`text-${isMobile ? 'sub' : ''}heading text-center`}>{`Results: ${programName}`}</div>
      <div className={`text-${isMobile ? 'sub' : ''}heading text-center text-bold`}>{activityName}</div>
    </div>
  );
};

const VotesActions = (props: { onRefreshClick?: () => void; onRefreshClickStatus?: QueryStatus }) => (
  <Affix className="text-center" offsetTop={72}>
    <Space size="middle" wrap className={css.actions}>
      <Button
        size="small"
        icon={<SyncOutlined />}
        shape="round"
        onClick={props.onRefreshClick}
        loading={props.onRefreshClickStatus === QueryStatus.pending}
      >
        Refresh
      </Button>
      <Button size="small" icon={<FileOutlined />} shape="round" disabled>
        Export
      </Button>
    </Space>
  </Affix>
);

const VotesTable = (props: { columns: ColumnsType<VotesData[number]>; dataSource: VotesData }) => (
  <Table<VotesData[number]>
    tableLayout="fixed"
    className={cn(css.table, css.votesTable)}
    columns={props.columns}
    dataSource={props.dataSource.map((v, key) => ({ ...v, key }))}
    pagination={false}
  />
);

type VotesContentProps = {
  activity: Activity;
  program: Program;
  total: number;
  values: VotesData;
  onRefreshClick?: () => void;
  onRefreshClickStatus?: QueryStatus;
};

const getOrderedValues = (values: VotesContentProps['values']) =>
  orderBy(values, (o) => (o.answer.value === 'Total' ? false : o.votes), ['desc']);

const VotesContent = ({ total, values, ...props }: VotesContentProps) => {
  const activityType = props.activity.type;
  const isRisingStar = activityType === ActivityTypes.RisingStar;

  const answerCol = getAnswerCol(activityType);
  const percentageCol = getPercentageCol(total);
  const columns = [answerCol, votesCol, percentageCol];
  const didNotAnswerCount = isRisingStar ? total - (values[0].votes + values[1].votes) : null;
  const dataSourceBase = [...values, { answer: { value: 'Total' }, votes: total }];
  const dataSource1 = createNextState(dataSourceBase, (draft) => {
    if (isRisingStar) {
      draft.splice(2, 0, { answer: { value: 'Did not answer' }, votes: didNotAnswerCount ?? 0 });
    }
  });
  const dataSource2 = isRisingStar
    ? createNextState(dataSourceBase, (draft) => {
        if (didNotAnswerCount !== null) {
          const index = findIndex(draft, ['answer', RisingStartAnswerId.No]);
          if (index !== -1) draft[index].votes += didNotAnswerCount;
        }
      })
    : null;

  return (
    <div className={css.votesContent}>
      <VotesHeader programName={props.program.name} activityName={props.activity.name} />
      <VotesActions onRefreshClick={props.onRefreshClick} onRefreshClickStatus={props.onRefreshClickStatus} />
      <div className={css.sections}>
        {dataSource2 && (
          <div style={{ margin: '16px 0 48px' }}>
            <h2 className="text-article text-center">Combined votes for all checked-in users</h2>

            <VotesTable columns={columns} dataSource={getOrderedValues(dataSource2)} />
          </div>
        )}

        <VotesTable columns={columns} dataSource={getOrderedValues(dataSource1)} />
      </div>
    </div>
  );
};

export default VotesContent;
