import React from 'react';
import { find } from 'lodash';
import { Row } from 'antd';
import { generatePath, useParams, Link } from 'react-router-dom';
import { Activity, ActivityTypes } from 'app/api/generated';
import { PROGRAM_LINEUP_ROUTE } from 'common/constants/routes';
import Page from 'common/components/Page';
import { useProgram } from 'features/program/Program';
import { initialValues as RisingStarInitialValues } from 'features/program/Lineup/Activities/RisingStar';
import { initialValues as InfoInitialValues } from 'features/program/Lineup/Activities/Info';
import { initialValues as PollInitialValues } from 'features/program/Lineup/Activities/Poll';
import { initialValues as YesNoInitialValues } from 'features/program/Lineup/Activities/YesNo';
import EditActivityForm from 'features/program/Lineup/EditActivity/EditActivityForm';

const initialActivityValues = {
  [ActivityTypes.RisingStar]: RisingStarInitialValues,
  [ActivityTypes.Poll]: PollInitialValues,
  [ActivityTypes.Info]: InfoInitialValues,
  [ActivityTypes.YesNo]: YesNoInitialValues,
};

const EditActivity = () => {
  const { program, newActivity } = useProgram();
  const { activityId = '' } = useParams<{ activityId: string }>();

  // Activity
  const activityData = newActivity ? newActivity : find(program.activities, ['id', activityId]);
  const activity: Partial<Activity> | null = activityData
    ? { ...(initialActivityValues[activityData.type] || {}), ...activityData }
    : null;

  if (!activity || !activity.type) {
    return (
      <div>
        <Page.Header>
          <Row align="middle" justify="space-between">
            <div className="text-title">Activity with id {`{ ${activityId} }`} not found</div>
          </Row>
        </Page.Header>
        <Page.Content>
          <Link className="text-heading" to={generatePath(PROGRAM_LINEUP_ROUTE, { programId: program.id })}>
            Go to lineup
          </Link>
        </Page.Content>
      </div>
    );
  }

  return <EditActivityForm activity={activity} activityId={activityId} />;
};

export default EditActivity;
