import React, { useEffect, useState } from 'react';
import { isEmpty, omit } from 'lodash';
import cn from 'classnames';
import { Affix, Col, message, Row, Space } from 'antd';
import { FormikProps, useFormik } from 'formik';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import {
  Activity,
  InfoActivity,
  PollActivity,
  RisingStarActivity,
  ActivityTypes,
  YesNoActivity,
} from 'app/api/generated';
import {
  useCreateInfoActivityMutation,
  useCreatePollActivityMutation,
  useCreateRisingStarActivityMutation,
  useUpdateInfoActivityMutation,
  useUpdatePollActivityMutation,
  useUpdateRisingStarActivityMutation,
  useCreateYesNoActivityMutation,
  useUpdateYesNoActivityMutation,
} from 'app/api';
import { isHebrew, sleep } from 'common/utils';
import Page from 'common/components/Page';
import Icon from 'common/components/Icons';
import Button from 'common/components/Button';
import { ACTIVITY_EDIT_ROUTE, PROGRAM_LINEUP_ROUTE } from 'common/constants/routes';
import { useProgram } from 'features/program/Program';
import Badge from 'features/program/Lineup/Badge';
import RisingStar, { ValidationSchema as RSValidationSchema } from 'features/program/Lineup/Activities/RisingStar';
import Info, { ValidationSchema as InfoValidationSchema } from 'features/program/Lineup/Activities/Info';
import Poll, { ValidationSchema as PollValidationSchema } from 'features/program/Lineup/Activities/Poll';
import YesNo, { ValidationSchema as YesNoValidationSchema } from 'features/program/Lineup/Activities/YesNo';
import DraftSymbol from 'features/program/DraftSymbol';
import css from './EditActivity.module.css';

// type guards
function isRisingStarActivity(activity: Partial<Activity>): activity is RisingStarActivity {
  return activity.type === ActivityTypes.RisingStar;
}

function isInfoActivity(activity: Partial<Activity>): activity is InfoActivity {
  return activity.type === ActivityTypes.Info;
}

function isPollActivity(activity: Partial<Activity>): activity is PollActivity {
  return activity.type === ActivityTypes.Poll;
}

function isYesNoActivity(activity: Partial<Activity>): activity is YesNoActivity {
  return activity.type === ActivityTypes.YesNo;
}

const validationSchemes = {
  [ActivityTypes.RisingStar]: RSValidationSchema,
  [ActivityTypes.Poll]: PollValidationSchema,
  [ActivityTypes.Info]: InfoValidationSchema,
  [ActivityTypes.YesNo]: YesNoValidationSchema,
};

const updateSuccess = () => message.success('Updated');

const Content = (props: { form: FormikProps<Partial<Activity>> }) => {
  switch (props.form.values.type) {
    case ActivityTypes.RisingStar:
      return <RisingStar form={props.form as FormikProps<Partial<RisingStarActivity>>} />;
    case ActivityTypes.Info:
      return <Info form={props.form as FormikProps<Partial<InfoActivity>>} />;
    case ActivityTypes.Poll:
      return <Poll form={props.form as FormikProps<Partial<PollActivity>>} />;
    case ActivityTypes.YesNo:
      return <YesNo form={props.form as FormikProps<Partial<YesNoActivity>>} />;
    default:
      return null;
  }
};

const EditActivityForm: React.FC<{ activity: Partial<Activity>; activityId: string }> = ({ activity, activityId }) => {
  const [isHeaderAffixed, setIsHeaderAffixed] = useState(false);
  const navigate = useNavigate();
  const { program, setNewActivity } = useProgram();

  const [createRisingStar] = useCreateRisingStarActivityMutation();
  const [createInfo] = useCreateInfoActivityMutation();
  const [createPoll] = useCreatePollActivityMutation();
  const [createYesNo] = useCreateYesNoActivityMutation();
  const [updateRisingStar] = useUpdateRisingStarActivityMutation();
  const [updateInfo] = useUpdateInfoActivityMutation();
  const [updatePoll] = useUpdatePollActivityMutation();
  const [updateYesNo] = useUpdateYesNoActivityMutation();

  const { id: programId } = program;
  const lineupUrl = generatePath(PROGRAM_LINEUP_ROUTE, { programId });

  // Activity
  const activityType = activity.type as ActivityTypes;
  const isNew = activity.id === undefined;

  const form = useFormik<Partial<Activity>>({
    initialValues: activity,
    validationSchema: validationSchemes[activityType],
    onSubmit: async (values) => {
      let newActivityId = '';

      if (isRisingStarActivity(values)) {
        // ----
        const payload = { programId, activity: omit(values, ['id', 'type', 'stage']) };
        if (isNew) {
          const response = await createRisingStar(payload).unwrap();
          if (response.activityId) newActivityId = response.activityId;
        } else if (activityId) {
          const response = await updateRisingStar({ activityId, ...payload }).unwrap();
          if (response.updateRisingStarActivity?.name) updateSuccess();
        }
        // ----
      } else if (isPollActivity(values)) {
        // ----
        const payload = { programId, activity: omit(values, ['id', 'type', 'stage']) };
        if (isNew) {
          const response = await createPoll(payload).unwrap();
          if (response.activityId) newActivityId = response.activityId;
        } else if (activityId) {
          const response = await updatePoll({ activityId, ...payload }).unwrap();
          if (response.updatePollActivity?.name) updateSuccess();
        }
        // ----
      } else if (isInfoActivity(values)) {
        // ----
        const payload = { programId, activity: omit(values, ['id', 'type', 'stage']) };
        if (isNew) {
          const response = await createInfo(payload).unwrap();
          if (response.activityId) newActivityId = response.activityId;
        } else if (activityId) {
          const response = await updateInfo({ activityId, ...payload }).unwrap();
          if (response.updateInfoActivity?.name) updateSuccess();
        }
      } else if (isYesNoActivity(values)) {
        const payload = { programId, activity: omit(values, ['id', 'type', 'stage']) };
        if (isNew) {
          const response = await createYesNo(payload).unwrap();
          if (response.activityId) newActivityId = response.activityId;
        } else if (activityId) {
          const response = await updateYesNo({ activityId, ...payload }).unwrap();
          if (response.updateYesNoActivity?.name) updateSuccess();
        }
      }

      if (newActivityId) {
        await sleep(500);
        setNewActivity(null);
        navigate(generatePath(ACTIVITY_EDIT_ROUTE, { programId, activityId: newActivityId }));
      }
    },
  });
  const { isValid } = form;

  useEffect(() => {
    if (!isValid) {
      message.error('Form is not valid');
      document.querySelector('[data-selector="error"]')?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [isValid]);

  return (
    <form onSubmit={form.handleSubmit} className={cn({ [css.headerFixed]: isHeaderAffixed })}>
      {isNew && <DraftSymbol />}
      <Affix offsetTop={-164} onChange={(affixed) => setIsHeaderAffixed(Boolean(affixed))}>
        <Page.Header className={css.header}>
          <Row align="middle" justify="space-between" wrap={false} style={{ overflow: 'hidden' }}>
            <Row
              align="middle"
              style={{ marginBottom: isHeaderAffixed ? '' : 8, gap: isHeaderAffixed ? 6 : '', overflow: 'hidden' }}
            >
              <Col flex="100%">
                <Link
                  to={lineupUrl}
                  style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  className={`${isHeaderAffixed ? 'text-article' : 'text-subheading'} text-ellipsis`}
                >
                  <Icon.LongArrowLeft width="22px" style={{ marginRight: 10, marginBottom: 4 }} />
                  <span className={cn('display-ib', { 'text-hebrew': isHebrew(program.name) })}>{program.name}</span>
                </Link>
              </Col>
              <Col>
                <Row align="middle" wrap={false} style={{ gap: isHeaderAffixed ? '12px' : '20px' }}>
                  <Badge type={activityType} />
                  <h1
                    className={cn(' text-ellipsis', isHeaderAffixed ? 'text-article' : 'text-title', {
                      'text-hebrew': isHebrew(form.values.name),
                    })}
                  >
                    {form.values.name}
                  </h1>
                </Row>
              </Col>
            </Row>
            <Space align="center" size={20}>
              <Button
                type="text"
                shape="round"
                size="large"
                onClick={() => navigate(lineupUrl)}
                title="Exit without save"
              >
                Cancel
              </Button>
              <Button
                customType="success"
                htmlType="submit"
                shape="round"
                size="large"
                title="Save"
                disabled={!form.isValid || form.isSubmitting || form.isValidating}
                loading={form.isSubmitting || form.isValidating}
              >
                Save
              </Button>
              <Button
                title="Save and exit"
                disabled={!form.isValid || form.isSubmitting || form.isValidating}
                shape="round"
                size="large"
                onClick={async () => {
                  const errors = await form.validateForm();

                  if (!isEmpty(errors)) {
                    return;
                  }

                  await form.submitForm();
                  navigate(lineupUrl);
                }}
              >
                Finish
              </Button>
            </Space>
          </Row>
        </Page.Header>
      </Affix>
      <Page.Content>{<Content form={form} />}</Page.Content>
    </form>
  );
};

export default EditActivityForm;
