import React, { memo } from 'react';
import cn from 'classnames';
import { Dropdown } from 'antd';
import { RgbaStringColorPicker } from 'react-colorful';
import { colord, extend } from 'colord';
import namesPlugin from 'colord/plugins/names';
import InputText, { InputTextPropsI } from 'common/components/InputText';
import css from './InputColor.module.css';
import Button from 'common/components/Button';

extend([namesPlugin]);

export interface InputColorPropsI extends InputTextPropsI {
  onColorChange?: (name: string, value: string) => void;
}

const InputColor = ({ className, onColorChange, ...props }: InputColorPropsI) => {
  const color = ((props.value || props.defaultValue) ?? '#fff') as string;
  const rgbaColor = color.startsWith('rgba') ? color : colord(color).toRgbString();

  const overlay = (
    <div>
      <RgbaStringColorPicker
        color={rgbaColor}
        onChange={(value) => {
          if (onColorChange && props.name) onColorChange(props.name, value);
        }}
      />
    </div>
  );

  return (
    <InputText
      {...props}
      className={cn(css.color, className)}
      suffix={
        <Dropdown trigger={['click']} overlay={overlay}>
          <Button className={css.colorButton} shape="circle" style={{ padding: 0 }}>
            <span style={{ backgroundColor: rgbaColor, width: '100%', height: '100%', borderRadius: 'inherit' }} />
          </Button>
        </Dropdown>
      }
    />
  );
};

export default memo(InputColor);
