import React from 'react';
import { find } from 'lodash';
import {
  Outlet,
  useParams,
  NavLink,
  NavLinkProps,
  generatePath,
  useOutletContext,
  useLocation,
} from 'react-router-dom';
import cn from 'classnames';
import { Space, Row } from 'antd';
import { useMediaQuery } from 'common/hooks/useMediaQuery';
import { Activity, Program, ActivityTypes } from 'app/api/generated';
import {
  PROGRAM_STATS_GENERAL_ROUTE,
  PROGRAM_STATS_WINNER_ROUTE,
  ACTIVITY_WINNER_STATS_ROUTE,
  ACTIVITY_VOTES_STATS_ROUTE,
  ACTIVITY_GENERAL_STATS_ROUTE,
  ACTIVITY_VOTES_BACKUP_STATS_ROUTE,
} from 'common/constants/routes';
import { useProgram } from '../Program';
import css from './Stats.module.css';

const navCN: NavLinkProps['className'] = ({ isActive }) => cn(css.navLink, { [css.active]: isActive });

export function useStatsContext() {
  return useOutletContext<{ activity: Activity | undefined; program: Program; isMobile: boolean }>();
}

const disabledProps = {
  'aria-disabled': 'true' as const,
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.preventDefault(),
  title: '🛠 In progress...',
};

const Stats = () => {
  const { program } = useProgram();
  const isMobile = useMediaQuery({ preset: 'mobile' });
  const location = useLocation();
  const params = useParams<{ programId: string; activityId?: string }>();
  const activity = find(program.activities, ['id', params.activityId]);

  const to = (link: string) => `${generatePath(link, params)}${location.search}`;
  const navSpaceSize: [number, number] = isMobile ? [0, 0] : [16, 0];

  return (
    <div className={css.stats}>
      <nav className={cn(css.nav, 'text-center', `text-${isMobile ? 'body' : 'article'}`)}>
        <Row className={css.container} align="middle" justify="center" style={{ height: '100%' }}>
          {activity ? (
            <Space size={navSpaceSize}>
              <NavLink to={to(ACTIVITY_WINNER_STATS_ROUTE)} className={navCN} {...disabledProps}>
                Winner
              </NavLink>
              <NavLink to={to(ACTIVITY_GENERAL_STATS_ROUTE)} className={navCN}>
                General
              </NavLink>
              {activity.type !== ActivityTypes.Info && (
                <>
                  <NavLink to={to(ACTIVITY_VOTES_STATS_ROUTE)} className={navCN}>
                    Votes
                  </NavLink>
                  <NavLink to={to(ACTIVITY_VOTES_BACKUP_STATS_ROUTE)} className={navCN}>
                    Backup Votes
                  </NavLink>
                </>
              )}
            </Space>
          ) : (
            <Space size={navSpaceSize}>
              <NavLink to={to(PROGRAM_STATS_WINNER_ROUTE)} className={navCN} {...disabledProps}>
                Winner
              </NavLink>
              <NavLink to={to(PROGRAM_STATS_GENERAL_ROUTE)} className={navCN}>
                General
              </NavLink>
            </Space>
          )}
        </Row>
      </nav>

      <div className={css.container}>
        <Outlet context={{ program, activity, isMobile }} />
      </div>
    </div>
  );
};

export default Stats;
