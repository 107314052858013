import React from 'react';
import Icon from 'common/components/Icons';
import Button from 'common/components/Button';
import { ActivityStages, ActivityTypes } from 'app/api/generated';

type StageBtnProps = {
  onClick: (stage: ActivityStages) => void;
  show: boolean;
  loading?: boolean;
  disabled?: boolean;
};

export const StageBtn = {
  StartVote: (props: StageBtnProps) =>
    props.show ? (
      <Button
        customType="accent"
        shape="round"
        size="small"
        icon={<Icon.Play />}
        loading={props.loading}
        disabled={props.disabled}
        onClick={() => props.onClick(ActivityStages.StartVote)}
      >
        Start vote
      </Button>
    ) : null,
  StopVote: (props: StageBtnProps) =>
    props.show ? (
      <Button
        danger
        shape="round"
        size="small"
        icon={<Icon.Stop />}
        loading={props.loading}
        disabled={props.disabled}
        onClick={() => props.onClick(ActivityStages.StopVote)}
      >
        Stop vote
      </Button>
    ) : null,
  StartCheckIn: (props: StageBtnProps) =>
    props.show ? (
      <Button
        customType="success"
        shape="round"
        size="small"
        icon={<Icon.Marker />}
        loading={props.loading}
        disabled={props.disabled}
        onClick={() => props.onClick(ActivityStages.StartCheckIn)}
      >
        Start Check In
      </Button>
    ) : null,
  StopCheckIn: (props: StageBtnProps) =>
    props.show ? (
      <Button
        danger
        shape="round"
        size="small"
        icon={<Icon.MarkerCancelled />}
        loading={props.loading}
        disabled={props.disabled}
        onClick={() => props.onClick(ActivityStages.StopCheckIn)}
      >
        Stop Check In
      </Button>
    ) : null,
  Hide: (props: StageBtnProps) =>
    props.show ? (
      <Button
        shape="round"
        size="small"
        icon={<Icon.EyeCancelled width="19px" style={{ marginTop: -2 }} />}
        loading={props.loading}
        disabled={props.disabled}
        onClick={() => props.onClick(ActivityStages.Hide)}
      >
        Hide
      </Button>
    ) : null,
  Show: (props: StageBtnProps) =>
    props.show ? (
      <Button
        customType="success"
        shape="round"
        size="small"
        icon={<Icon.Eye width="19px" />}
        loading={props.loading}
        disabled={props.disabled}
        onClick={() => props.onClick(ActivityStages.Show)}
      >
        Show
      </Button>
    ) : null,
};

interface IsShowBtnI {
  btnType: ActivityStages;
  activityType: ActivityTypes;
  stage: ActivityStages;
  force: boolean;
}

export function isShow({ force, stage, ...props }: IsShowBtnI) {
  switch (props.btnType) {
    case ActivityStages.StartCheckIn:
      switch (props.activityType) {
        case ActivityTypes.RisingStar:
          return force || stage === ActivityStages.Hide || stage === ActivityStages.Show;
        default:
          return false;
      }

    case ActivityStages.StopCheckIn:
      switch (props.activityType) {
        case ActivityTypes.RisingStar:
          return force || stage === ActivityStages.StartCheckIn;
        default:
          return false;
      }

    case ActivityStages.StartVote:
      switch (props.activityType) {
        case ActivityTypes.Poll:
        case ActivityTypes.YesNo:
          return force || stage === ActivityStages.Hide || stage === ActivityStages.Show;
        case ActivityTypes.RisingStar:
          return force || stage === ActivityStages.StartCheckIn || stage === ActivityStages.StopCheckIn;
        default:
          return false;
      }

    case ActivityStages.StopVote:
      switch (props.activityType) {
        case ActivityTypes.Poll:
        case ActivityTypes.YesNo:
        case ActivityTypes.RisingStar:
          return force || stage === ActivityStages.StartVote;
        default:
          return false;
      }

    case ActivityStages.Hide:
      return force || stage === ActivityStages.Show || stage === ActivityStages.StopVote;

    case ActivityStages.Show:
      switch (props.activityType) {
        case ActivityTypes.Info:
          return force || stage === ActivityStages.Hide;
        default:
          return false;
      }

    default:
      return false;
  }
}
