import React from 'react';
import moment from 'moment';
import { random } from 'lodash';
import { ColumnsType } from 'antd/es/table';
import { CaretUpOutlined, CheckOutlined } from '@ant-design/icons';

interface ProgramItemI {
  fullName: string;
  score: number;
  createdAt: string;
  updatedAt: string;
  ip: string;
  provider: string;
  totalAnswerTime: string;
  totalCorrectAnswers?: string;
}

function ProgramItem(): ProgramItemI {
  return {
    fullName: ['מעיין גוטשלק', ' נעם קורן'][Math.round(Math.random())],
    score: random(100),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    ip: '37.142.166.223',
    provider: 'form',
    totalAnswerTime: '5942.7829995155',
    totalCorrectAnswers: `${random(15)}/15`,
  };
}

interface ActivityItemI {
  fullName: string;
  score: number;
  createdAt: string;
  updatedAt: string;
  ip: string;
  provider: string;
  cheating: boolean;
  choice: string;
}

function ActivityItem(): ActivityItemI {
  const int = Math.round(Math.random());
  return {
    fullName: ['מעיין גוטשלק', ' נעם קורן'][int],
    score: 5,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    ip: '37.142.166.223',
    provider: 'form',
    cheating: !!int,
    choice: ['לא עבר', 'עבר'][int],
  };
}

export interface ColumnDataTypeI extends ActivityItemI, ProgramItemI {
  key: string;
}

interface MockupI {
  program: {
    data: {
      title: string;
      items: ProgramItemI[];
    };
    columns: ColumnsType<ColumnDataTypeI>;
  };
  activity: {
    data: {
      title: string;
      items: ActivityItemI[];
    };
    columns: ColumnsType<ColumnDataTypeI>;
  };
}

export const mockup: MockupI = {
  program: {
    data: {
      title: 'הכוכב הבא 2022 יום צילום חמישי',
      items: Array.from({ length: 100 }).map(ProgramItem),
    },
    columns: [
      {
        title: () => (
          <>
            Name
            <CaretUpOutlined className="ant-custom-filter-icon" />
          </>
        ),
        width: 200,
        dataIndex: 'fullName',
        key: 'fullName',
        sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      },
      {
        title: () => (
          <>
            Score
            <CaretUpOutlined className="ant-custom-filter-icon" />
          </>
        ),
        dataIndex: 'score',
        key: 'score',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.score - b.score,
      },
      {
        title: 'Provider',
        dataIndex: 'provider',
        key: 'provider',
      },
      {
        title: 'Answer Time',
        dataIndex: 'totalAnswerTime',
        key: 'totalAnswerTime',
      },
      {
        title: 'Correct Answers',
        dataIndex: 'totalCorrectAnswers',
        key: 'totalCorrectAnswers',
      },
      {
        title: 'IP',
        dataIndex: 'ip',
        key: 'ip',
      },
      {
        title: 'Created at',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (v) => <small>{moment(v).format('MMM Do YY, HH:mm:ss')}</small>,
      },
      {
        title: 'Updated at',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (v) => <small>{moment(v).format('MMM Do YY, HH:mm:ss')}</small>,
      },
    ],
  },
  activity: {
    data: {
      title: 'ינאי בן חמו',
      items: Array.from({ length: 100 }).map(ActivityItem),
    },
    columns: [
      {
        title: () => (
          <>
            Name
            <CaretUpOutlined className="ant-custom-filter-icon" />
          </>
        ),
        width: 200,
        dataIndex: 'fullName',
        key: 'fullName',
        sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      },
      {
        title: () => (
          <>
            Score
            <CaretUpOutlined className="ant-custom-filter-icon" />
          </>
        ),
        dataIndex: 'score',
        key: 'score',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.score - b.score,
      },
      {
        title: 'Provider',
        dataIndex: 'provider',
        key: 'provider',
      },
      {
        title: 'Cheating',
        dataIndex: 'cheating',
        key: 'cheating',
        render: (v) => (v ? <CheckOutlined /> : null),
      },
      {
        title: 'Choice',
        dataIndex: 'choice',
        key: 'choice',
      },
      {
        title: 'IP',
        dataIndex: 'ip',
        key: 'ip',
      },
      {
        title: 'Created at',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (v) => <small>{moment(v).format('MMM Do YY, HH:mm:ss')}</small>,
      },
      {
        title: 'Updated at',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (v) => <small>{moment(v).format('MMM Do YY, HH:mm:ss')}</small>,
      },
    ],
  },
};
