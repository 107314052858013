import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import { Spin } from 'antd';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'app/hooks';
import InputText from 'common/components/InputText';
import Page from 'common/components/Page';
import Button from 'common/components/Button';
import { PROGRAMS_ALL_ROUTE } from 'common/constants/routes';

import { selectIsLoggedIn, userLogin, userGetMe, selectGetMeStatus, selectLoginStatus } from './userSlice';
import css from './Login.module.css';

type LocationState = null | {
  from?: Location;
};

const Login = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const getMeStatus = useSelector(selectGetMeStatus);
  const loginStatus = useSelector(selectLoginStatus);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as LocationState)?.from;

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`${from?.pathname ?? PROGRAMS_ALL_ROUTE}${from?.search ?? ''}`);
    } else {
      dispatch(userGetMe());
    }
  }, [from, dispatch, navigate, isLoggedIn]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values, helpers) => {
      const result = await dispatch(userLogin(values));
      if (isRejectedWithValue(result) && typeof result.payload === 'string') {
        helpers.setErrors({ password: result.payload });
      }
    },
  });

  if (getMeStatus === QueryStatus.pending) {
    return (
      <Spin
        className="fullscreen-spin"
        tip="Authorizing"
        indicator={<LoadingOutlined style={{ fontSize: 48, marginBottom: 16 }} spin />}
      />
    );
  }

  return (
    <>
      <Page.Header>
        <h1 className="text-title">Login</h1>
      </Page.Header>
      <Page.Content className={css.content}>
        <form onSubmit={formik.handleSubmit}>
          <InputText
            className={css.field}
            maxLength={100}
            showCount
            id="email"
            label="Email"
            touched={formik.touched.email}
            error={formik.errors.email}
            {...formik.getFieldProps('email')}
          />
          <InputText
            className={css.field}
            id="password"
            component="Password"
            label="Password"
            touched={formik.touched.password}
            error={formik.errors.password}
            {...formik.getFieldProps('password')}
          />

          <Button htmlType="submit" loading={loginStatus === QueryStatus.pending} shape="round" size="large">
            Log In
          </Button>
        </form>
      </Page.Content>
    </>
  );
};

export default Login;
