import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { get, isEmpty } from 'lodash';
import { Affix, message, Row, Space } from 'antd';
import { createNextState, Draft } from '@reduxjs/toolkit';
import { generatePath, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import Page from 'common/components/Page';
import Icons from 'common/components/Icons';
import Button from 'common/components/Button';
import { PROGRAM_EDIT_ROUTE, PROGRAM_LINEUP_ROUTE, PROGRAMS_ALL_ROUTE } from 'common/constants/routes';
import Toggle, { TogglePropsI, getToggleFieldProps } from 'common/components/Toggle';
import Select, { SelectPropsI } from 'common/components/Select';
import Upload, { UploadPropsI } from 'common/components/Upload';
import InputText, { InputTextPropsI } from 'common/components/InputText';
import InputColor, { InputColorPropsI } from 'common/components/InputColor';
import TextArea, { TextAreaPropsI } from 'common/components/TextArea';
import { ANCHOR_SECTION_CLASSNAME } from 'common/components/SideNavigation';

import { Program, RegistrationType } from 'app/api/generated';
import {
  useActivateProgramMutation,
  useCreateProgramMutation,
  useDeleteProgramMutation,
  useUpdateProgramMutation,
  useCreateProgramWinnersSheetMutation,
} from 'app/api';
import css from 'features/program/Program.module.css';
import ValidationSchema from 'features/program/validations';
import { fields, newProgram, sections } from 'features/program/constants';
import RegistrationFields, { fields as registrationFields } from 'features/program/RegistrationFields';
import { isRegistrationFieldConfig } from 'features/program/types';
import { useProgram } from 'features/program/Program';
import DraftSymbol from 'features/program/DraftSymbol';

// drop inactive and undefined fields
const dropInactiveFields = (draft: Draft<Program>['registration']) => {
  if (draft?.fields?.length) draft.fields = draft.fields.filter((field) => field && field.active);
};

const ProgramContent = () => {
  const [isHeaderAffixed, setIsHeaderAffixed] = useState(false);
  const navigate = useNavigate();
  const { program } = useProgram();
  const [updateProgram] = useUpdateProgramMutation();
  const [createProgram] = useCreateProgramMutation();
  const [activateProgram] = useActivateProgramMutation();
  const [deleteProgram] = useDeleteProgramMutation();
  const [createProgramWinnersSheet, createProgramWinnersSheetState] = useCreateProgramWinnersSheetMutation();
  const activeRef = useRef(program.active);
  const isNew = program.id === newProgram.id;

  const handleSaveActive = async (active: boolean, id = program.id) => {
    if (activeRef.current !== active) {
      if (active) await activateProgram({ id });
      else await deleteProgram({ id });
    }

    activeRef.current = active;
  };

  const handleUpdateProgram = async ({ active, id, name, slug, registration, tos, theme, script }: Program) => {
    const [response] = await Promise.all([
      updateProgram({
        id,
        program: {
          slug: slug || program.id,
          name,
          registration: createNextState(registration, dropInactiveFields),
          tos,
          theme,
          script,
        },
      }).unwrap(),
      handleSaveActive(active),
    ]);

    if (response.program) message.success('Saved!');
  };

  const handleCreateProgram = async (values: Program) => {
    const response = await createProgram({
      program: {
        name: values.name,
        slug: values.slug,
        registration: createNextState(values.registration, dropInactiveFields),
        tos: values.tos,
        theme: values.theme,
        script: values.script,
      },
    }).unwrap();
    if (response.id) {
      await handleSaveActive(values.active, response.id);

      navigate(generatePath(PROGRAM_EDIT_ROUTE, { programId: response.id }));
      message.success('Created!');
    }
  };

  const form = useFormik<Program>({
    initialValues: createNextState(program, (draft) => {
      // registration
      if (draft.registration?.fields?.length) {
        const fields = draft.registration.fields;
        const orderedNames = fields.map((field) => field.name);
        // transform fields to ordered array by fields order in form
        draft.registration.fields = registrationFields.map(({ name }) => fields[orderedNames.indexOf(name)]);
      }
    }),
    validationSchema: ValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.id === newProgram.id) return handleCreateProgram(values);

      return handleUpdateProgram(values);
    },
  });

  const isRegistrationActive = form.values.registration?.active;
  const { isValid } = form;

  useEffect(() => {
    if (!isValid) {
      message.error('Form is not valid');
      document.querySelector('[data-selector="error"]')?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [isValid]);

  return (
    <form onSubmit={form.handleSubmit} className={cn({ [css.headerFixed]: isHeaderAffixed })}>
      {isNew && <DraftSymbol />}
      <Affix offsetTop={-164} onChange={(affixed) => setIsHeaderAffixed(Boolean(affixed))}>
        <Page.Header className={css.header}>
          <Row align="middle" justify={isHeaderAffixed ? 'end' : 'space-between'}>
            <h1 className="text-title">Program settings</h1>
            <Space align="center" size={20}>
              <Button
                disabled={!form.isValid || form.isSubmitting || form.isValidating}
                shape="round"
                size="large"
                onClick={async () => {
                  if (form.dirty) {
                    const errors = await form.validateForm();
                    if (!isEmpty(errors)) return;
                    await form.submitForm();
                  }
                  navigate(PROGRAMS_ALL_ROUTE);
                }}
              >
                Finish
              </Button>
              <Button
                loading={createProgramWinnersSheetState.isLoading}
                shape="round"
                size="large"
                onClick={async () => {
                  const response = await createProgramWinnersSheet({ programId: program.id });

                  if ('data' in response) {
                    window.open(response.data.program.url, '_blank');
                  }
                }}
              >
                Winners
              </Button>
              <Button
                type="primary"
                shape="round"
                size="large"
                icon={<Icons.Lineup width="15px" styles={{ marginBottom: 2 }} />}
                onClick={() => {
                  navigate(generatePath(PROGRAM_LINEUP_ROUTE, { programId: program.id }));
                }}
              >
                Lineup
              </Button>
              <Button
                customType="success"
                shape="round"
                size="large"
                htmlType="submit"
                disabled={!form.isValid || form.isSubmitting || form.isValidating}
                loading={form.isSubmitting || form.isValidating}
              >
                Save
              </Button>
            </Space>
          </Row>
        </Page.Header>
      </Affix>

      <Page.Content>
        {Object.values(sections).map((section) => (
          <section
            id={section.id}
            key={`section-${section.id}`}
            className={cn(css.section, ANCHOR_SECTION_CLASSNAME)}
            style={{ pointerEvents: form.isSubmitting ? 'none' : undefined }}
          >
            <h2 className="text-subtitle">{section.title}</h2>
            <div className={css.fields}>
              {fields[section.id].map((config) => {
                if (isRegistrationFieldConfig(config)) {
                  return isRegistrationActive && form.values.registration?.type !== RegistrationType.Native ? (
                    <RegistrationFields key={`field-${section.id}-regFields`} form={form} />
                  ) : null;
                }

                const { componentType, defaultValuePath, ...field } = config;

                if (section.id === 'registration') {
                  if (
                    // hide registration fields
                    (!isRegistrationActive && field.name !== 'registration.active') ||
                    // hide dismiss text field
                    (!form.values.registration?.showDismiss && field.name === 'registration.dismiss')
                  ) {
                    return null;
                  }
                }

                // hide tos fields
                if (section.id === 'tos' && !form.values.tos?.active && field.name !== 'tos.active') {
                  return null;
                }

                const commonProps = {
                  key: `field-${section.id}-${field.name}`,
                  className: css.field,
                  touched: get(form.touched, field.name),
                  error: get(form.errors, field.name),
                };

                switch (componentType) {
                  case 'toggle':
                    return (
                      <Toggle
                        {...commonProps}
                        {...(field as TogglePropsI)}
                        {...getToggleFieldProps(form.getFieldProps(field.name))}
                        onChange={form.setFieldValue}
                      />
                    );
                  case 'select':
                    return (
                      <Select
                        {...commonProps}
                        {...(field as SelectPropsI)}
                        {...form.getFieldProps(field.name)}
                        onChange={form.setFieldValue}
                      />
                    );
                  case 'file':
                    return (
                      <Upload
                        {...commonProps}
                        {...(field as UploadPropsI)}
                        {...form.getFieldProps(field.name)}
                        onFileChange={form.setFieldValue}
                      />
                    );
                  case 'color':
                    return (
                      <InputColor
                        {...commonProps}
                        {...(field as InputColorPropsI)}
                        {...form.getFieldProps(field.name)}
                        onColorChange={form.setFieldValue}
                      />
                    );
                  case 'textarea':
                    return (
                      <TextArea {...commonProps} {...(field as TextAreaPropsI)} {...form.getFieldProps(field.name)} />
                    );
                  default:
                    return (
                      <InputText
                        placeholder={field.name === 'slug' ? program.id : undefined}
                        {...commonProps}
                        {...(field as InputTextPropsI)}
                        {...form.getFieldProps(field.name)}
                      />
                    );
                }
              })}
            </div>
          </section>
        ))}
      </Page.Content>
    </form>
  );
};

export default ProgramContent;
