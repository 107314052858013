import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Dropdown, Menu, message, Popconfirm, Row, Space } from 'antd';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { BarChartOutlined, MoreOutlined } from '@ant-design/icons';
import { generatePath, Link } from 'react-router-dom';
import { useUpdateActivityStageMutation } from 'app/api';
import * as pubnub from 'app/api/pubnub';
import { Activity, ActivityStages, ActivityTypes, MutationDeleteActivityArgs } from 'app/api/generated';
import { isHebrew } from 'common/utils';
import Button from 'common/components/Button';
import { ACTIVITY_EDIT_ROUTE, ACTIVITY_GENERAL_STATS_ROUTE } from 'common/constants/routes';
import Badge from 'features/program/Lineup/Badge';
import LineupHistoryStorage from 'features/program/Lineup/LineupHistoryStorage';
import { StageBtn, isShow } from 'features/program/Lineup/StageBtn';
import css from 'features/program/Lineup/Lineup.module.css';

type Props = {
  isViewer: boolean;
  item: Activity;
  programId: string;
  onDuplicate: (props: { programId: string; activity: Activity }) => void;
  onDelete: (props: MutationDeleteActivityArgs) => void;
  forceShowButtons: boolean;
};

const FeedItemMenu: React.FC<{
  programId: string;
  activity: Activity;
  onDuplicate: Props['onDuplicate'];
  onDelete: () => Promise<void>;
}> = ({ programId, activity, onDuplicate, onDelete }) => (
  <Menu
    items={[
      {
        key: '1',
        label: <Link to={generatePath(ACTIVITY_EDIT_ROUTE, { programId, activityId: activity.id })}>Edit</Link>,
      },
      {
        key: '2',
        label: 'Duplicate',
        onClick: () => onDuplicate({ programId, activity }),
      },
      {
        key: '3',
        label: (
          <Popconfirm title={`Delete activity: "${activity.name}"？`} okText="Yes" cancelText="No" onConfirm={onDelete}>
            <div className={css.btnDelete}>Delete</div>
          </Popconfirm>
        ),
      },
    ]}
  />
);

const FeedItem: React.FC<Props> = ({ item, programId, onDuplicate, onDelete, forceShowButtons, isViewer }) => {
  const { type, id: activityId } = item;
  const currStage = item.stage as ActivityStages;
  const [updateActivityStageMutation] = useUpdateActivityStageMutation();
  const [nextStage, setNextStage] = useState<ActivityStages | null>(null);
  const isLoading = (stage: ActivityStages) => nextStage === stage && stage !== currStage;
  const setStage = useCallback(
    async (stage: ActivityStages) => {
      try {
        setNextStage(stage);
        updateActivityStageMutation({ programId, activityId, stage });
        await pubnub.updateActivityStage({ activity: { ...item, stage }, programId });
      } catch (e) {
        setNextStage(null);
        message.error((e as Error).message || 'Something went wrong');
      }
    },
    [updateActivityStageMutation, item, programId, activityId]
  );
  const deleteActivity = useCallback(async () => {
    if (currStage !== ActivityStages.Hide) await setStage(ActivityStages.Hide);
    onDelete({ programId, activityId });
  }, [setStage, onDelete, programId, activityId, currStage]);
  const historyStorage = useRef(new LineupHistoryStorage({ activityId, programId }));

  useEffect(() => {
    setNextStage(null);
  }, [currStage]);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: activityId,
  });

  const isBtnDisabled = nextStage !== null;

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? 1 : undefined,
    position: isDragging ? ('relative' as const) : undefined,
  };

  const isShowStageBtn = (btnType: ActivityStages) =>
    isShow({ btnType, activityType: type as ActivityTypes, stage: currStage, force: forceShowButtons }) && !isViewer;

  return (
    <li
      className={cn(css.feedItem, {
        [css.dragging]: isDragging,
        [css.active]:
          currStage === ActivityStages.Show ||
          currStage === ActivityStages.StartVote ||
          currStage === ActivityStages.StopVote ||
          currStage === ActivityStages.StartCheckIn ||
          currStage === ActivityStages.StopCheckIn,
        [css.voted]: historyStorage.current.has(),
      })}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className={css.feedItemI}>
        {isViewer ? (
          <div style={{ width: 'var(--feed-item-handle-width)' }} />
        ) : (
          <div className={css.feedItemHandle} {...listeners}>
            <MoreOutlined />
          </div>
        )}
        <Row className={css.feedItemContent} justify="space-between" gutter={[0, 6]} wrap={false}>
          <Space align="center" size={9}>
            <Badge type={type as ActivityTypes} />
            <Link
              className={cn(css.feedItemLink, { 'text-hebrew': isHebrew(item.name) })}
              to={generatePath(ACTIVITY_EDIT_ROUTE, { programId, activityId })}
              target="_blank"
              title="Open in a new tab"
            >
              {item.name}
            </Link>
          </Space>
          <Space className={css.feedItemActions} align="center" size={9}>
            <StageBtn.StartCheckIn
              disabled={isBtnDisabled}
              onClick={setStage}
              loading={isLoading(ActivityStages.StartCheckIn)}
              show={isShowStageBtn(ActivityStages.StartCheckIn)}
            />

            <StageBtn.StopCheckIn
              disabled={isBtnDisabled}
              onClick={setStage}
              loading={isLoading(ActivityStages.StopCheckIn)}
              show={isShowStageBtn(ActivityStages.StopCheckIn)}
            />

            <StageBtn.StartVote
              disabled={isBtnDisabled}
              onClick={setStage}
              loading={isLoading(ActivityStages.StartVote)}
              show={isShowStageBtn(ActivityStages.StartVote)}
            />

            <StageBtn.StopVote
              disabled={isBtnDisabled}
              onClick={setStage}
              show={isShowStageBtn(ActivityStages.StopVote)}
              loading={isLoading(ActivityStages.StopVote)}
            />

            <StageBtn.Hide
              disabled={isBtnDisabled}
              onClick={setStage}
              show={isShowStageBtn(ActivityStages.Hide)}
              loading={isLoading(ActivityStages.Hide)}
            />

            <StageBtn.Show
              disabled={isBtnDisabled}
              onClick={setStage}
              show={currStage === ActivityStages.Hide && type === ActivityTypes.Info && !isViewer}
              loading={isLoading(ActivityStages.Show)}
            />

            {/* stats */}
            <Link
              to={`${generatePath(ACTIVITY_GENERAL_STATS_ROUTE, {
                programId,
                activityId,
              })}?type=${item.type.toLowerCase()}`}
              target="_blank"
              style={{ opacity: 1 }}
            >
              <Button customType="white" shape="circle" size="small" icon={<BarChartOutlined width="18px" />} />
            </Link>
            {!isViewer && (
              <Dropdown.Button
                overlay={
                  <FeedItemMenu
                    programId={programId}
                    activity={item}
                    onDuplicate={onDuplicate}
                    onDelete={deleteActivity}
                  />
                }
              />
            )}
          </Space>
        </Row>
      </div>
    </li>
  );
};

export default FeedItem;
