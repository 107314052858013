import moment from 'moment';
import { find, findLast, sumBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { message, Row, Spin, Statistic } from 'antd';
import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchMessages, PNMessageTypes } from 'app/api/pubnub';
import {
  Activity,
  ActivityStages,
  ActivityTypes,
  PollActivity,
  RisingStarActivity,
  YesNoActivity,
} from 'app/api/generated';
import css from 'features/program/Stats/Stats.module.css';
import { RisingStartAnswerId, YesNoAnswerId } from 'features/program/types';
import { processData, useBackupData, useBackupDataQuery } from 'features/program/Stats/Votes/VotesBackup';

const Loader = () => (
  <Spin
    size="large"
    className="fullscreen-spin"
    tip="Fetching..."
    indicator={<LoadingOutlined style={{ fontSize: 48, marginBottom: 16 }} spin />}
  />
);

// message.activity type guard check
const isEntireActivity = (activity: Message['activity']): activity is Activity => 'name' in activity;

type Message = {
  type: PNMessageTypes.ActivityStageUpdate;
  activity: Activity | { id: Activity['id']; stage: Activity['stage'] };
};

const useRecentActivity = ({ programId }: { programId: string }) => {
  const [data, setData] = React.useState<{ timetoken: number; activity: Activity } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getRecentActivity = async () => {
      setIsLoading(true);

      try {
        const messages = await fetchMessages<Message>({ programId, count: 10 });
        const start = findLast(messages, (o) => {
          if (isEntireActivity(o.message.activity)) {
            const { type, stage } = o.message.activity;
            return (
              (type === ActivityTypes.Poll && stage === ActivityStages.StartVote) ||
              (type === ActivityTypes.YesNo && stage === ActivityStages.StartVote) ||
              (type === ActivityTypes.RisingStar && stage === ActivityStages.StartCheckIn)
            );
          }
          return false;
        });

        // Save
        if (start && isEntireActivity(start.message.activity)) {
          const end = findLast(messages, ['message.activity.id', start.message.activity.id]);
          setData({
            timetoken: +start.timetoken,
            activity: {
              ...start.message.activity,
              stage: end?.message.activity.stage || start.message.activity.stage,
            },
          });
        } else {
          setData(null);
        }
      } catch (error) {
        message.error(`pubnub/getMessages: ${(error as Error).message}`);
      }

      setIsLoading(false);
    };

    if (programId) {
      getRecentActivity();
    }
  }, [programId]);

  return { data, isLoading };
};

const STATUS_CODE: Record<ActivityStages, number> = {
  [ActivityStages.StartCheckIn]: 3,
  [ActivityStages.StopCheckIn]: 4,
  [ActivityStages.StartVote]: 1,
  [ActivityStages.StopVote]: 0,
  [ActivityStages.Show]: 1,
  [ActivityStages.Hide]: 0,
};

const createdAt = +moment().subtract(30, 'days');

const Code = ({ children }: { children: React.ReactNode }) => (
  <Row justify="center" style={{ marginBottom: 25 }}>
    <code style={{ whiteSpace: 'break-spaces' }}>{children}</code>
  </Row>
);

const Container = (props: { children: React.ReactNode; title: React.ReactNode }) => (
  <div className={css.stats}>
    <div className={css.section}>
      <h1 className="text-subheading text-center">{props.title}</h1>
      <hr />
      {props.children}
    </div>
  </div>
);

const ActivityComponent = (props: { activity: Activity; timetoken: number; programId: string }) => {
  const query = useBackupDataQuery({
    programId: props.programId,
    createdAt,
    activityId: props.activity.id,
    activityType: props.activity.type,
    activityAnswers: (props.activity as PollActivity).answers,
  });
  const { values: rawData, isLoading } = useBackupData(query);

  if (isLoading || !rawData) {
    return <Loader />;
  }

  const { values, total } = processData({
    rawData,
    activityType: props.activity.type,
    activity: props.activity,
    programId: props.programId,
  });

  // https://support.pubnub.com/hc/en-us/articles/360051495812-How-do-I-convert-the-PubNub-timetoken-to-Unix-timestamp-
  const timeStartRaw = Math.round(props.timetoken / Math.pow(10, 4));
  const timeTickRaw = Math.round(Date.now());
  const timeRelativeRaw = timeTickRaw - timeStartRaw;

  const timeStart = moment(timeStartRaw).format('MMM Do YY, HH:mm:ss');
  const timeRelative = moment.utc(timeRelativeRaw).format('HH:mm:ss');
  const timeTick = moment(timeTickRaw).format('MMM Do YY, HH:mm:ss');

  switch (props.activity.type) {
    case ActivityTypes.RisingStar: {
      const activity = props.activity as RisingStarActivity;
      const votedYes = find(values, ['answer', RisingStartAnswerId.Yes]) || { votes: 0 };
      const totalVoted = sumBy(values, 'votes');
      const id = activity.xmlVoteId === '' ? '—' : activity.xmlVoteId ?? '—';
      const type = 2;
      const status = STATUS_CODE[activity.stage];
      const result = (votedYes.votes ? (votedYes.votes / total) * 100 : 0).toFixed(2);

      return (
        <>
          <Container title="Rising Star">
            <Statistic title="ID" value={id} />
            <Statistic title="Type" value={type} />
            <Statistic title="Status" value={status} />
            <Statistic title="Time Start" value={timeStart} />
            <Statistic title="Time Tick" value={timeTick} />
            <Statistic title="Time Relative(hh:mm:ss)" value={timeRelative} />
            <Statistic title="Checked In" value={total} />
            <Statistic title="Voted" value={totalVoted} />
            <Statistic title="Competitor ID" value={id} />
            <Statistic title="Result" value={result} />
          </Container>
          <Code>
            {`<vote id="${id}" status="${status}" type="${type}" timeStart="${timeStartRaw}">
      <tick time="${timeTickRaw}" relativeTime="${timeRelativeRaw}" checkedIn="${total}" voted="${totalVoted}">
          <competitor competitorID="${id}">${result}</competitor>
      </tick>
  </vote>`}
          </Code>
        </>
      );
    }

    case ActivityTypes.YesNo: {
      const activity = props.activity as YesNoActivity;
      const votedYes = find(values, ['answer', YesNoAnswerId.Yes]) || { votes: 0 };
      const totalVoted = sumBy(values, 'votes');
      const id = activity.xmlVoteId === '' ? '—' : activity.xmlVoteId ?? '—';
      const type = 2;
      const status = STATUS_CODE[activity.stage];
      const result = (votedYes.votes ? (votedYes.votes / total) * 100 : 0).toFixed(2);

      return (
        <>
          <Container title="Your voice">
            <Statistic title="ID" value={id} />
            <Statistic title="Type" value={type} />
            <Statistic title="Status" value={status} />
            <Statistic title="Time Start" value={timeStart} />
            <Statistic title="Time Tick" value={timeTick} />
            <Statistic title="Time Relative(hh:mm:ss)" value={timeRelative} />
            <Statistic title="Voted" value={totalVoted} />
            <Statistic title="Competitor ID" value={id} />
            <Statistic title="Result" value={result} />
          </Container>
          <Code>
            {`<vote id="${id}" status="${status}" type="${type}" timeStart="${timeStartRaw}">
      <tick time="${timeTickRaw}" relativeTime="${timeRelativeRaw}" checkedIn="${total}" voted="${totalVoted}">
          <competitor competitorID="${id}">${result}</competitor>
      </tick>
  </vote>`}
          </Code>
        </>
      );
    }

    case ActivityTypes.Poll: {
      const activity = props.activity as PollActivity;
      const id = activity.xmlVoteId === '' ? '—' : activity.xmlVoteId ?? '—';
      const type = activity.answers.length;
      const status = STATUS_CODE[activity.stage];
      return (
        <>
          <Container title="Poll">
            <Statistic title="ID" value={id} />
            <Statistic title="Type" value={type} />
            <Statistic title="Status" value={status} />
            <Statistic title="Time Start" value={timeStart} />
            <Statistic title="Time Tick" value={timeTick} />
            <Statistic title="Time Relative(hh:mm:ss)" value={timeRelative} />
            {activity.answers.map(({ xmlAnswerId, text, image }) => {
              const votes = find(values, ['xmlAnswerId', xmlAnswerId])?.votes;
              return (
                <Statistic
                  key={`answer-${xmlAnswerId}`}
                  title={`Answer ID: ${xmlAnswerId}`}
                  value={(votes ? (votes / total) * 100 : 0).toFixed(2)}
                />
              );
            })}
          </Container>
          <Code>
            {`<vote id="${id}" status="${status}" type="${type}" timeStart="${timeStartRaw}">
      <tick time="${timeTickRaw}" relativeTime="${timeRelativeRaw}">
    ${activity.answers
      .map(({ xmlAnswerId, text, image }) => {
        const votes = find(values, ['xmlAnswerId', xmlAnswerId])?.votes;
        const value = (votes ? (votes / total) * 100 : 0).toFixed(2);
        return `\t<option id="${xmlAnswerId}">${value}</option>`;
      })
      .join('\n')}
      </tick>>
  </vote>`}
          </Code>
        </>
      );
    }
    default:
      return null;
  }
};

const XmlVotesBackup = () => {
  const { programId = '' } = useParams();
  const { data: recentActivity, isLoading } = useRecentActivity({ programId });

  if (isLoading) {
    return <Loader />;
  }

  if (!recentActivity) {
    return <h1 className="text-subheading text-center">No activity yet</h1>;
  }

  return <ActivityComponent programId={programId} {...recentActivity} />;
};

export default XmlVotesBackup;
