import React from 'react';
import cn from 'classnames';
import { Button as AntdButton, ButtonProps } from 'antd';
import css from './Button.module.css';

interface Props extends ButtonProps {
  customType?: 'accent' | 'dimmed' | 'success' | 'white'; // addition color variation
}

const Button = ({ className, customType, ...props }: Props) => {
  return <AntdButton {...props} className={cn(css.btn, className, customType ? css[customType] : '')} />;
};

export default Button;
