import React from 'react';
import * as y from 'yup';
import { FormikProps } from 'formik';
import { Row, Col } from 'antd';
import { RisingStarActivity, RisingStarActivityInput, ActivityTypes } from 'app/api/generated';
import Upload from 'common/components/Upload';
import InputText from 'common/components/InputText';
import Toggle, { getToggleFieldProps } from 'common/components/Toggle';
import { MAX_ACTIVITY_NAME_LEN } from 'features/program/validations';
import css from 'features/program/Program.module.css';

const RisingStar = ({ form }: { form: FormikProps<Partial<RisingStarActivity>> }) => {
  return (
    <div style={{ width: 530, maxWidth: '100%' }}>
      <InputText
        {...form.getFieldProps('name')}
        touched={form.touched.name}
        error={form.errors.name}
        label="Activity name"
        maxLength={MAX_ACTIVITY_NAME_LEN}
        showCount
        placeholder="Type your Activity name"
        autoComplete="off"
        className={css.field}
      />
      <InputText
        {...form.getFieldProps('xmlVoteId')}
        touched={form.touched.xmlVoteId}
        error={form.errors.xmlVoteId}
        label="XML vote ID"
        placeholder="XML_name_mako"
        autoComplete="off"
        className={css.field}
      />
      <InputText
        {...form.getFieldProps('singerName')}
        touched={form.touched.singerName}
        error={form.errors.singerName}
        label="Artist name"
        placeholder="לדוגמה: נטע ברזילי"
        autoComplete="off"
        className={css.field}
      />
      <Upload
        {...form.getFieldProps('singerImage')}
        touched={form.touched.singerImage}
        error={form.errors.singerImage}
        accept="image/png, image/jpeg"
        defaultPlaceholder="Image"
        limit={300}
        placeholder={<p>JPG, PNG | 300KB</p>}
        label="Artist image"
        onFileChange={form.setFieldValue}
        className={css.field}
      />
      <InputText
        {...form.getFieldProps('questionText')}
        touched={form.touched.questionText}
        error={form.errors.questionText}
        label="Question text"
        placeholder="האם נטע תרים את המסך?"
        autoComplete="off"
        className={css.field}
      />
      <InputText
        {...form.getFieldProps('songName')}
        touched={form.touched.songName}
        error={form.errors.songName}
        label="Song name"
        placeholder=" השיר של נטע"
        autoComplete="off"
        className={css.field}
      />
      <Toggle
        {...getToggleFieldProps(form.getFieldProps('showFlare'))}
        touched={form.touched.showFlare}
        error={form.errors.showFlare}
        label="Show flare"
        className={css.field}
        onChange={form.setFieldValue}
      />
      <Row gutter={16}>
        <Col span={12}>
          <InputText
            {...form.getFieldProps('qualifiedText')}
            touched={form.touched.qualifiedText}
            error={form.errors.qualifiedText}
            label="Vote passed text"
            placeholder="עברה!"
            autoComplete="off"
            className={css.field}
          />
        </Col>
        <Col span={12}>
          <InputText
            {...form.getFieldProps('disqualifiedText')}
            touched={form.touched.disqualifiedText}
            error={form.errors.disqualifiedText}
            label="Vote not passed text"
            placeholder="לא עברה"
            autoComplete="off"
            className={css.field}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <InputText
            {...form.getFieldProps('thankYou')}
            touched={form.touched.thankYou}
            error={form.errors.thankYou}
            label="Thank you"
            placeholder="תודה שהשתתפתם"
            autoComplete="off"
            className={css.field}
          />
        </Col>
        <Col span={12}>
          <InputText
            {...form.getFieldProps('continue')}
            touched={form.touched.continue}
            error={form.errors.continue}
            label="Continue"
            placeholder="מיד ממשיכים"
            autoComplete="off"
            className={css.field}
          />
        </Col>
      </Row>
      <InputText
        {...form.getFieldProps('finalText')}
        touched={form.touched.finalText}
        error={form.errors.finalText}
        autoComplete="off"
        className={css.field}
        hidden
      />
    </div>
  );
};

export const ValidationSchema: y.SchemaOf<RisingStarActivityInput> = y.object().shape({
  name: y.string().required('Required'),
  singerName: y.string().required('Required'),
  singerImage: y.string().required('Required'),
  songName: y.string().required('Required'),
  questionText: y.string().required('Required'),
  qualifiedText: y.string().default(''),
  disqualifiedText: y.string().default(''),
  xmlVoteId: y.string().required(),
  finalText: y.string().min(0).default(''),
  thankYou: y.string().optional().nullable().default(''),
  continue: y.string().optional().nullable().default(''),
  showFlare: y.boolean().optional().nullable(),
});

export const initialValues: Partial<RisingStarActivity> = {
  type: ActivityTypes.RisingStar,
  finalText: '',
  qualifiedText: '',
  disqualifiedText: '',
  thankYou: '',
  continue: '',
  showFlare: false,
};

export default RisingStar;
