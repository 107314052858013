import React from 'react';
import moment from 'moment';
import { CreateProgramRequest, RegistrationType } from 'app/api/generated';
import { AnchorProps } from 'common/components/SideNavigation';
import { FieldConfigType, SectionID, SectionsType } from 'features/program/types';

enum LogoPlacementType {
  default = 'default',
  stretch = 'stretch',
}

export const sections: SectionsType = {
  general: { id: 'general', title: 'General' },
  registration: { id: 'registration', title: 'Registration' },
  tos: { id: 'tos', title: 'Terms of use' },
  graphics: { id: 'graphics', title: 'Graphics' },
  pixels: { id: 'pixels', title: 'Pixels' },
};

export const fields: Record<SectionID, Array<FieldConfigType>> = {
  general: [
    { componentType: 'toggle', name: 'active', label: 'Status' },
    {
      componentType: 'text',
      name: 'name',
      label: 'Program Name',
      labelHint: 'Unique',
      maxLength: 100,
      showCount: true,
    },
    {
      componentType: 'text',
      name: 'slug',
      label: 'URL Name',
      labelHint: "Unique. Only letters, number and '_'",
      maxLength: 100,
      showCount: true,
    },
  ],
  registration: [
    { componentType: 'toggle', name: 'registration.active', label: 'Status' },
    {
      componentType: 'select',
      name: 'registration.type',
      label: 'Type of registration',
      placeholder: 'Select from option',
      defaultValue: RegistrationType.Form,
      options: [
        { value: RegistrationType.Form, label: 'Info form' },
        { value: RegistrationType.Native, label: 'Native app' },
        { value: RegistrationType.FormAndNative, label: 'Info form & Native app' },
      ],
    },
    {
      componentType: 'text',
      name: 'registration.title',
      label: 'Registration Title',
      placeholder: 'Type title here',
    },
    {
      componentType: 'textarea',
      name: 'registration.subtitle',
      label: 'Registration Subtitle',
      placeholder: 'Type subtitle here',
      autoSize: { minRows: 3, maxRows: 12 },
      maxLength: 100,
      showCount: true,
    },
    {
      componentType: 'file',
      name: 'registration.pic',
      label: 'Registration image',
      placeholder: <p>JPG, PNG | 300KB</p>,
      accept: 'image/png, image/jpeg',
      defaultPlaceholder: 'Image',
      limit: 300,
    },
    { componentType: 'text', name: 'registration.submit', label: 'Submit Button text', placeholder: 'Submit' },
    { componentType: 'toggle', name: 'registration.showDismiss', label: 'Dismiss button' },
    { componentType: 'text', name: 'registration.dismiss', label: 'Dismiss button text', placeholder: 'Skip' },
    { componentType: 'registrationFields' },
  ],
  tos: [
    { componentType: 'toggle', name: 'tos.active', label: 'Status' },
    {
      componentType: 'select',
      name: 'tos.showCheckbox',
      label: 'Show checkbox',
      placeholder: 'Select from option',
      defaultValue: 'Yes',
      options: [
        // @ts-expect-error mock
        { value: true, label: 'Yes' },
        // @ts-expect-error mock
        { value: false, label: 'No' },
      ],
    },
    {
      componentType: 'text',
      name: 'tos.text',
      label: 'Terms of use text',
      labelHint: 'Linked text should be wrapped in { } brackets',
      placeholder: 'I have read and agreed to the { terms and conditions }.',
    },
    {
      componentType: 'file',
      name: 'tos.url',
      label: 'Terms of use file',
      placeholder: <p>PDF | 5MB</p>,
      accept: 'application/pdf',
      defaultPlaceholder: 'File',
      limit: 1024 * 5,
    },
  ],
  graphics: [
    {
      componentType: 'file',
      name: 'theme.logo',
      label: 'Logo',
      placeholder: <p>JPG, PNG, SVG | 300KB</p>,
      accept: 'image/png, image/jpeg, image/svg+xml',
      defaultPlaceholder: 'Image',
      limit: 300,
    },
    {
      componentType: 'select',
      name: 'theme.logoPlacement',
      label: 'Logo placement',
      placeholder: 'Select from option',
      defaultValue: LogoPlacementType.default,
      options: [
        { value: LogoPlacementType.default, label: 'Default' },
        { value: LogoPlacementType.stretch, label: 'Stretch' },
      ],
    },
    { componentType: 'color', name: 'theme.bgColor', label: 'Background color', placeholder: '#FFFFFF' },
    {
      componentType: 'file',
      name: 'theme.bgImage',
      label: 'Background image',
      placeholder: <p>JPG, PNG, SVG | 300KB</p>,
      accept: 'image/png, image/jpeg, image/svg+xml',
      defaultPlaceholder: 'Image',
      limit: 300,
    },
    { componentType: 'color', name: 'theme.primaryColor', label: 'Main color', placeholder: '#FFFFFF' },
    { componentType: 'color', name: 'theme.dimmedColor', label: 'Secondary color', placeholder: '#000000' },
    {
      componentType: 'color',
      name: 'theme.btnBgColor',
      label: 'Button background color',
      placeholder: '#FFFFFF',
      defaultValuePath: 'theme.primaryColor',
    },
    {
      componentType: 'color',
      name: 'theme.btnColor',
      label: 'Text button color',
      placeholder: '#000000',
      defaultValuePath: '#theme.dimmedColor',
    },
  ],
  pixels: [
    {
      componentType: 'textarea',
      name: 'script',
      label: 'Script',
      placeholder: 'pixel code',
      autoSize: { minRows: 10, maxRows: 20 },
      maxLength: 1000,
      showCount: true,
    },
  ],
};

export const programNavigation: AnchorProps = {
  navType: 'anchor',
  title: 'Program settings',
  links: Object.values(sections),
};

export const newProgram: CreateProgramRequest & { id: 'new'; active: true } = {
  id: 'new',
  active: true,
  name: `Untitled program ${moment(Date.now()).format('MMM Do YY, HH:mm:ss')}`,
  registration: {
    active: false,
    title: '',
    type: RegistrationType.Form,
    dismiss: '',
    submit: '',
    showDismiss: false,
    subtitle: '',
    fields: [],
  },
  tos: {
    active: false,
    url: '',
    text: '',
    showCheckbox: true,
  },
  theme: {
    logoPlacement: LogoPlacementType.default,
    primaryColor: 'rgba(255, 255, 255, 1)',
    dimmedColor: 'rgba(186, 147, 87, 1)',
    btnBgColor: 'rgba(186, 147, 87, 1)',
    btnColor: 'rgba(255, 255, 255, 1)',
    bgColor: 'rgba(0, 0, 0, 1)',
  },
};
