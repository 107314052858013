import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import { LOGIN_ROUTE } from 'common/constants/routes';
import { selectIsLoggedIn } from 'features/user/userSlice';

type Props = { element: JSX.Element };

const PrivateRoute: React.FC<Props> = ({ element }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to={LOGIN_ROUTE} state={{ from: location }} />;
  }

  return element;
};

export default PrivateRoute;
