import React, { useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams, Outlet, useOutletContext } from 'react-router-dom';
import { withErrorBoundary } from '@sentry/react';

import { ActivityTypes, Program as ProgramType } from 'app/api/generated';
import { useGetProgramQuery } from 'app/api';
import { newProgram } from './constants';

type NewActivity = { name: string; type: ActivityTypes } | null;

type ContextType = {
  program: ProgramType;
  newActivity: NewActivity;
  setNewActivity: (data: NewActivity) => void;
};

export function useProgram() {
  return useOutletContext<ContextType>();
}

const Program = () => {
  const { programId = '' } = useParams<{ programId: string }>();
  const [newActivity, setNewActivity] = useState<NewActivity>(null);
  const { data, isLoading, isError } = useGetProgramQuery({ id: programId }, { skip: programId === newProgram.id });
  const program = data?.program || newProgram;

  if (isError) {
    return <div>{`Cannot get program by id(${programId})`}</div>;
  }

  if (!programId || isLoading || !program) {
    return (
      <Spin
        size="large"
        className="fullscreen-spin"
        tip="Fetch program..."
        indicator={<LoadingOutlined style={{ fontSize: 48, marginBottom: 16 }} spin />}
      />
    );
  }

  return <Outlet context={{ program, newActivity, setNewActivity }} />;
};

export default withErrorBoundary(Program, { fallback: <p>An error has occurred</p> });
