import React from 'react';

const DraftSymbol = () => (
  <div
    className="text-footnote"
    style={{
      position: 'fixed',
      right: 4,
      bottom: 4,
      color: 'var(--color-black60)',
    }}
  >
    draft
  </div>
);

export default DraftSymbol;
