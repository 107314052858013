import React from 'react';
import { Affix, Space, Table } from 'antd';
import { TrophyOutlined, FileOutlined, SyncOutlined } from '@ant-design/icons';
import Button from 'common/components/Button';
import { useStatsContext } from 'features/program/Stats';
import { mockup, ColumnDataTypeI } from './winners.mock';
import css from './Stats.module.css';

const Winners: React.FC<{ type: 'activity' | 'program' }> = (props) => {
  const { isMobile } = useStatsContext();
  const { data, columns } = mockup[props.type];

  return (
    <>
      <div className={css.header}>
        <div className={`text-${isMobile ? 'sub' : ''}heading text-center`}>
          <TrophyOutlined /> Winners
        </div>
        <div className={`text-${isMobile ? 'sub' : ''}title text-center`}>{data.title}</div>
      </div>
      <Affix className="text-center" offsetTop={72}>
        <Space size="middle" wrap className={css.actions}>
          <Button size="small" icon={<SyncOutlined />} shape="round">
            Refresh
          </Button>
          <Button size="small" icon={<FileOutlined />} shape="round">
            Export
          </Button>
          <Button size="small" icon={<FileOutlined />} shape="round">
            Export top 100
          </Button>
          <Button size="small" icon={<FileOutlined />} shape="round">
            Export all votes
          </Button>
        </Space>
      </Affix>
      <Table
        scroll={isMobile ? { x: 1200, y: isMobile ? window.innerHeight / 2 : undefined } : undefined}
        className={css.table}
        columns={columns.map((col, index) =>
          index === 0 ? { ...col, width: isMobile ? 150 : 200, fixed: isMobile ? 'left' : undefined } : col
        )}
        dataSource={data.items.map((item, index) => {
          return { key: `${index}`, ...item } as ColumnDataTypeI;
        })}
        pagination={{
          pageSize: 50,
          showSizeChanger: false,
        }}
      />
    </>
  );
};

export default Winners;
