import React, { ReactNode, memo } from 'react';
import cn from 'classnames';
import { CaretDownOutlined } from '@ant-design/icons';
import { Select as AntdSelect, SelectProps } from 'antd';
import Error from 'common/components/Error';
import css from './Select.module.css';

export interface SelectPropsI extends Omit<SelectProps, 'onChange'> {
  label?: ReactNode;
  error?: string;
  touched?: boolean;
  name: string;
  // usually expected to receive `formik.setFieldValue`
  onChange?: (name: string, value: string) => void;
}

const Select = ({ className, label, touched, error, onChange, ...props }: SelectPropsI) => {
  return (
    <div className={cn(css.select, 'text-label', className)}>
      <div className={css.label}>{label}</div>
      <AntdSelect
        {...props}
        suffixIcon={<CaretDownOutlined />}
        dropdownClassName={css.dropdown}
        onChange={(value, option) => onChange?.(props.name, value)}
      />
      {error ? <Error>{error}</Error> : null}
    </div>
  );
};

export default memo(Select);
