import { ActivityTypes } from 'app/api/generated';
import React from 'react';

const Badge = (props: { type: ActivityTypes; style?: object }) => {
  let str = '';
  let backgroundColor = 'black';

  switch (props.type) {
    case ActivityTypes.RisingStar:
      str = 'star';
      backgroundColor = '#9E7F04';
      break;
    case ActivityTypes.Info:
      str = 'info';
      break;
    case ActivityTypes.Poll:
      str = 'poll';
      backgroundColor = '#F1C40D';
      break;
    case ActivityTypes.YesNo:
      str = 'voice';
      backgroundColor = '#04719F';
      break;
    default:
      break;
  }
  return (
    <span
      className="text-description"
      style={{ backgroundColor, borderRadius: '2em', color: 'white', padding: '6px 16px', ...props.style }}
    >
      {str}
    </span>
  );
};

export default Badge;
