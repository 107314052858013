import React, { ReactNode, memo, useState } from 'react';
import cn from 'classnames';
import { Upload as AntdUpload, message, Space } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload/interface';
import { FilePdfOutlined, LoadingOutlined, DeleteOutlined, PictureOutlined } from '@ant-design/icons';
import { useCreatePreSignedUrlMutation } from 'app/api';
import { domain } from 'common/utils';
import Button from 'common/components/Button';
import ErrorComponent from 'common/components/Error';
import { useProgram } from 'features/program/Program';
import css from './Select.module.css';

export interface UploadPropsI extends UploadProps {
  label?: ReactNode;
  placeholder?: ReactNode;
  error?: string;
  touched?: boolean;
  name?: string;
  // size limit in KB
  limit?: number;
  value?: string;
  onError?: (name: string, value: string) => void;
  onFileChange?: (field: string, value: string | undefined, shouldValidate?: boolean | undefined) => void;
  defaultPlaceholder?: 'File' | 'Image' | null;
  preset?: 'preset1';
}

const bytesToKb = (size: number) => size / 1024;

const pdfRegex = /(^https?:\/\/.*\/.*\.(pdf)\??.*$)|(application\/pdf)/gim;

const DefaultFilePlaceholder = () => (
  <>
    <p className="ant-upload-drag-icon">
      <FilePdfOutlined />
    </p>
    <p className="ant-upload-text">Click or drag file to this area to upload</p>
  </>
);

const DefaultImagePlaceholder = () => (
  <>
    <p className="ant-upload-drag-icon">
      <PictureOutlined />
    </p>
    <p className="ant-upload-text">Click or drag file to this area to upload</p>
  </>
);

const DefaultPlaceholder = {
  File: DefaultFilePlaceholder,
  Image: DefaultImagePlaceholder,
};

const { Dragger } = AntdUpload;

export const uploadPreSignedFile = (params: { url: string; fields: Record<string, string>; file: File }) => {
  const formData = new FormData();
  Object.entries({ ...params.fields, file: params.file }).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return fetch(params.url, {
    method: 'POST',
    body: formData,
  });
};

const Upload: React.FC<UploadPropsI> = ({
  className,
  label,
  touched,
  error,
  defaultPlaceholder,
  placeholder,
  limit,
  value,
  onError,
  onFileChange,
  ...props
}) => {
  const [createPreSignedUrl] = useCreatePreSignedUrlMutation();
  const { program } = useProgram();
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState<null | string>(value || null);
  const [customError, setCustomError] = useState<null | string>(null);
  const DefaultPlaceholderComponent = (defaultPlaceholder && DefaultPlaceholder[defaultPlaceholder]) || null;

  return (
    <div className={cn(css.upload, className, { [css.preset1]: props.preset === 'preset1' })}>
      <div className="text-label">
        {label && <div className={css.label}>{label}</div>}
        <Dragger
          multiple={false}
          maxCount={1}
          listType="picture-card"
          showUploadList={false}
          {...props}
          // validate
          beforeUpload={(file) => {
            if (limit !== undefined && bytesToKb(file.size) > limit) {
              const msg = `Image must smaller than ${limit}KB!`;
              // show a message
              message.error(msg, 4);
              // set component level error
              setCustomError(msg);
              // set form level error
              if (props.name && onError) onError(props.name, msg);

              return false;
            }

            return true;
          }}
          // upload
          customRequest={async (options) => {
            try {
              const { uploadProgramFile: preSigned } = await createPreSignedUrl({
                filename: (options.file as RcFile).name,
                id: program.id,
              }).unwrap();

              await uploadPreSignedFile({
                url: preSigned.url,
                fields: {
                  ...preSigned.fields,
                  'Content-Type': preSigned.mimetype,
                  acl: 'public-read',
                },
                file: options.file as RcFile,
              });

              options.onSuccess?.({ url: `${domain.assets}/${preSigned.fields.key}` });
            } catch (e) {
              const msg = (e as Error).message;
              // set component level error
              setCustomError(msg);
              // set form level error
              if (props.name && onError) onError(props.name, msg);
              // ...
              options.onError?.(e as Error);
            }
          }}
          // update field
          onChange={(info) => {
            const { status } = info.file;
            const url = info.file.response?.url;

            switch (status) {
              case 'uploading':
                setLoading(true);
                break;
              case 'done':
                setFileUrl(url);
                setLoading(false);
                setCustomError(null);
                if (onFileChange && props.name) onFileChange(props.name, url);
                break;
              case 'error':
                break;
              default:
                break;
            }
          }}
        >
          {fileUrl ? (
            <Space className={css.preview}>
              {fileUrl.match(pdfRegex) ? (
                <span className={css.asset}>{fileUrl.substring(fileUrl.lastIndexOf('/') + 1)}</span>
              ) : (
                <img className={css.asset} src={fileUrl} alt="avatar" />
              )}

              <Button
                className={css.remove}
                shape="circle"
                type="text"
                danger
                onClick={(event) => {
                  event.stopPropagation();
                  setFileUrl(null);
                  setCustomError(null);
                  if (props.name && onFileChange) onFileChange(props.name, '');
                }}
              >
                <DeleteOutlined />
              </Button>
            </Space>
          ) : (
            <div className={css.placeholder}>
              {loading ? (
                <div className={css.loading}>
                  <LoadingOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              ) : null}
              {DefaultPlaceholderComponent && !loading && <DefaultPlaceholderComponent />}
              {placeholder}
            </div>
          )}
        </Dragger>
      </div>
      {/* form level error */}
      {error ? <ErrorComponent>{error}</ErrorComponent> : null}
      {/* field level error */}
      {customError ? <ErrorComponent>{customError}</ErrorComponent> : null}
    </div>
  );
};

export default memo(Upload);
