import './index.css';
import './index-antd-override.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { store } from 'app/store';
import App from 'app/App';
import reportWebVitals from './reportWebVitals';
import json from '../package.json';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://5a3c06f80e884f479d255ee3651657d0@o1358184.ingest.sentry.io/6647329',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],

    normalizeDepth: 10,
    tracesSampleRate: 0.2,
    release: `${json.name}@${json.version}`,
  });
}

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
