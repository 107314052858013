import { useEffect, useState } from 'react';
import { useWindowSize } from './useWindowSize';

type EitherField<T, TKey extends keyof T = keyof T> = TKey extends keyof T
  ? { [P in TKey]-?: T[TKey] } & Partial<Record<Exclude<keyof T, TKey>, never>>
  : never;

const isMatching = (query: string) => window.matchMedia(query).matches;
const presetMap = {
  mobile: '(max-width: 767px)',
};

export const useMediaQuery = ({ query, preset }: EitherField<{ query?: string; preset?: 'mobile' }>) => {
  const windowWidth = useWindowSize({ debounce: 150 });
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    let q: string | undefined;
    if (preset && presetMap[preset]) q = presetMap[preset];
    else if (query) q = query;

    setIsMatch(q ? isMatching(q) : false);
  }, [query, preset, windowWidth]);

  return isMatch;
};
