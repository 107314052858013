import React, { useCallback, useState } from 'react';
import * as y from 'yup';
import { Col, Modal, Row, Popconfirm, message } from 'antd';
import { useFormik } from 'formik';
import Button from 'common/components/Button';
import { prependUrlProtocol } from 'common/utils';
import InputText from 'common/components/InputText';
import { publishEmergencyRedirect } from 'app/api/pubnub';

interface FormValuesI {
  url: string;
}

type ModalContentProps = {
  handleClose: () => void;
  programId: string;
};

const ModalContent = (props: ModalContentProps) => {
  const form = useFormik<FormValuesI>({
    initialValues: { url: '' },
    validationSchema: y.object().shape({
      url: y
        .string()
        // eslint-disable-next-line
        .matches(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, ' ')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        await publishEmergencyRedirect({
          programId: props.programId,
          url: prependUrlProtocol({ url: values.url }),
        });
        props.handleClose();
      } catch (e) {
        message.error((e as Error).message, 5);
      }
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Row justify="end">
        <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={form.submitForm}>
          <Button
            htmlType="submit"
            danger
            shape="round"
            size="large"
            loading={form.isSubmitting || form.isValidating}
            disabled={form.values.url.trim() === '' || form.errors.url !== undefined}
          >
            Redirect
          </Button>
        </Popconfirm>
      </Row>
      <Row align="middle" style={{ minHeight: '55vh' }} justify="center" gutter={16}>
        <Col span={20}>
          <InputText
            {...form.getFieldProps('url')}
            error={form.errors.url}
            touched={form.touched.url}
            label="Redirect url"
            placeholder="https://..."
            autoComplete="off"
            preset="text-hero"
          />
        </Col>
      </Row>
    </form>
  );
};

const Emergency = ({ programId }: { programId: string }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const handleClose = useCallback(() => {
    setIsShowModal(false);
  }, []);

  return (
    <>
      <Button
        type="text"
        size="small"
        danger
        onClick={() => setIsShowModal(true)}
        style={{
          position: 'fixed',
          right: 94,
          top: 176,
          opacity: 0.85,
          zIndex: 10,
        }}
      >
        Emergency redirect
      </Button>
      <Modal
        centered
        visible={isShowModal}
        onCancel={handleClose}
        width={970}
        closable={false}
        footer={null}
        destroyOnClose
      >
        <ModalContent handleClose={handleClose} programId={programId} />
      </Modal>
    </>
  );
};

export default Emergency;
