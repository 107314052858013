import PubNub from 'pubnub';
import { Activity, ActivityStages } from 'app/api/generated';
import { isProd } from 'common/utils';

const publishKey = isProd ? 'pub-c-5f16fee8-8d2c-4225-927e-5025cfa9c090' : 'pub-c-ecb04637-ce60-4dc4-91df-3b9d4a755336';
const subscribeKey = isProd
  ? 'sub-c-cf0d0005-a7f4-4dd7-bb8b-453f7644b57b'
  : 'sub-c-ec9aa3aa-cb5f-4552-9330-7a6c96674207';

const pubnub = new PubNub({
  publishKey,
  subscribeKey,
  uuid: 'dashboard',
  restore: true,
});

export enum PNMessageTypes {
  ActivityStageUpdate = 'activity_stage_update',
}

export function updateActivityStage(params: { programId: string; activity: Activity }) {
  const sendEntireActivity = [ActivityStages.Show, ActivityStages.StartCheckIn, ActivityStages.StartVote];
  const activity = sendEntireActivity.includes(params.activity.stage as ActivityStages)
    ? params.activity
    : {
        id: params.activity.id,
        stage: params.activity.stage,
      };

  return pubnub.publish({
    channel: params.programId,
    message: JSON.stringify({
      type: PNMessageTypes.ActivityStageUpdate,
      activity,
    }),
  });
}

export async function fetchMessages<T>({ programId, count }: { programId: string; count?: number }) {
  const history = await pubnub.fetchMessages({ channels: [programId], count });
  const messages = history.channels[programId];
  return messages.map((data) => ({ ...data, message: JSON.parse(data.message) as T }));
}

export function getProgramPresence(id: string) {
  return pubnub.hereNow({
    channels: [id],
    includeState: false,
    includeUUIDs: false,
  });
}

export function subscribeChannel(id: string) {
  return pubnub.subscribe({ channels: [id] });
}

export function unsubscribeChannel(id: string) {
  return pubnub.unsubscribe({ channels: [id] });
}

export function addListener(params: PubNub.ListenerParameters) {
  return pubnub.addListener(params);
}

export function removeListener(params: PubNub.ListenerParameters) {
  return pubnub.removeListener(params);
}

interface MessageCountMoreI {
  [channelId: string]: {
    is_more: boolean;
    url: string;
  };
}

interface GetMessageCountParamsI {
  channels: string[];
  channelTimetokens: string[];
  prevResult?: { [channelId: string]: number };
}

export async function getMessagesCount(
  channels: GetMessageCountParamsI['channels'],
  channelTimetokens: GetMessageCountParamsI['channelTimetokens'],
  prevResult?: GetMessageCountParamsI['prevResult']
): Promise<{ [channelId: string]: number }> {
  const response: PubNub.MessageCountsResponse & {
    more: MessageCountMoreI;
    error: boolean;
    error_message?: string;
    message?: string;
    status: number;
  } = await fetch(
    `https://ps.pndsn.com/v3/history/sub-key/${subscribeKey}/message-counts/${encodeURIComponent(channels.join(','))}?${
      channelTimetokens.length > 1
        ? `channelsTimetoken=${encodeURIComponent(channelTimetokens.join(','))}`
        : `timetoken=${channelTimetokens[0]}`
    }&uuid=${pubnub.getUUID()}&pnsdk=PubNub-JS-Web%2F7.2.0`,
    {
      headers: { accept: '*/*' },
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
    }
  ).then((r) => r.json());

  if (response.error) {
    throw new Error(response.message || response.error_message);
  }

  const result = response.channels;

  if (prevResult) {
    Object.entries(prevResult).forEach(([channelId, prevCount]) => {
      result[channelId] = (result[channelId] ?? 0) + prevCount;
    });
  }

  const morePayload = Object.entries(response.more ?? {}).reduce<{
    channels: GetMessageCountParamsI['channels'];
    channelTimetokens: GetMessageCountParamsI['channelTimetokens'];
  }>(
    (memo, [channelId, data]) => {
      if (data.is_more) {
        const url = new URL(`${window.location.origin}${data.url}`);
        const timetoken = url.searchParams.get('timetoken');
        if (timetoken) {
          memo.channels.push(channelId);
          memo.channelTimetokens.push(timetoken);
        }
      }

      return memo;
    },
    { channels: [], channelTimetokens: [] }
  );

  if (morePayload.channels.length) {
    return getMessagesCount(morePayload.channels, morePayload.channelTimetokens, result);
  }

  return result;
}

export function publishEmergencyRedirect(params: { programId: string; url: string }) {
  return pubnub.publish({
    channel: params.programId,
    message: JSON.stringify({
      type: 'action',
      secret: window.btoa(params.programId),
      url: params.url,
    }),
  });
}
