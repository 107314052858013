import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Modal, Row, Space, Layout, Affix } from 'antd';
import cn from 'classnames';
import { generatePath, Link } from 'react-router-dom';
import { BarChartOutlined, PlusOutlined } from '@ant-design/icons';
import phone from 'assets/phone.png';
import { useAppDispatch } from 'app/hooks';
import * as pubnub from 'app/api/pubnub';
import { getProgramLiveUrl, getProgramXmlUrl, isHebrew } from 'common/utils';
import Page from 'common/components/Page';
import Button from 'common/components/Button';
import Icon from 'common/components/Icons';
import { PROGRAM_EDIT_ROUTE, PROGRAM_STATS_GENERAL_ROUTE, XML_VOTE_ROUTE } from 'common/constants/routes';
import { setIsAsideCollapsed } from 'features/user/userSlice';
import { useProgram } from 'features/program/Program';
import CreateActivity from 'features/program/Lineup/CreateActivity';
import Feed from 'features/program/Lineup/Feed';
import css from './Lineup.module.css';

const useLiveUsersCount = ({ programId }: { programId: string }) => {
  const [count, setCount] = useState(0);
  const isFirstRender = useRef(true);
  const setProgramPresenceCount = useCallback(async () => {
    const { totalOccupancy } = await pubnub.getProgramPresence(programId);
    setCount(totalOccupancy);
  }, [programId]);

  useEffect(() => {
    if (isFirstRender.current) {
      setProgramPresenceCount();
      isFirstRender.current = false;
    }

    const interval = setInterval(setProgramPresenceCount, 1000 * 60 * 3);
    return () => {
      clearInterval(interval);
    };
  }, [setProgramPresenceCount]);

  return count;
};

const LiveCounter = (props: { count: number; programId: string }) => {
  const count = useLiveUsersCount({ programId: props.programId });

  return (
    <div className="text-subheading" style={{ letterSpacing: -1, lineHeight: 1 }}>
      {count > 100000 ? `${(count / 1000).toFixed(0)}K` : count}
    </div>
  );
};

const Lineup = () => {
  const dispatch = useAppDispatch();
  const { program, setNewActivity } = useProgram();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const liveUrl = getProgramLiveUrl(program.slug ?? program.id);
  const liveXML = getProgramXmlUrl(program.id);

  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false);
  const handleClose = useCallback(() => {
    setIsVisibleCreateModal(false);
  }, []);

  useEffect(() => {
    dispatch(setIsAsideCollapsed(true));
    return () => {
      dispatch(setIsAsideCollapsed(false));
    };
  }, [dispatch]);

  return (
    <div className={css.lineup}>
      <Page.Header>
        <Row align="middle" justify="space-between" wrap={false} style={{ overflow: 'hidden', gap: 32 }}>
          <Row style={{ overflow: 'hidden', flexDirection: 'column' }} gutter={[0, 8]}>
            <Col>
              <Link className={css.title} to={generatePath(PROGRAM_EDIT_ROUTE, { programId: program.id })}>
                <Icon.Edit width="28px" />
                <h1 className={cn('text-title', 'text-ellipsis', { 'text-hebrew': isHebrew(program.name) })}>
                  {program.name}
                </h1>
              </Link>
            </Col>
            <Col>
              <a href={liveUrl} target="_blank" rel="noopener noreferrer" style={{ color: 'var(--color-primary)' }}>
                {liveUrl}
              </a>
              <br />
              <div>
                <a href={liveXML} target="_blank" rel="noopener noreferrer" style={{ color: 'var(--color-primary)' }}>
                  XML
                </a>
                {' | '}
                <Link
                  to={generatePath(XML_VOTE_ROUTE, { programId: program.id })}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'var(--color-primary)' }}
                >
                  XML Backup
                </Link>
              </div>
            </Col>
          </Row>
          <Space align="center" size={20}>
            <Space size={10}>
              <Icon.User style={{ color: 'var(--color-black12)' }} />
              <div>
                <LiveCounter count={program.usersLiveCount ?? 0} programId={program.id} />
                <div className="text-label" style={{ whiteSpace: 'nowrap' }}>
                  Users real time
                </div>
              </div>
            </Space>
            <Link to={generatePath(PROGRAM_STATS_GENERAL_ROUTE, { programId: program.id })} target="_blank">
              <Button shape="round" size="large" icon={<BarChartOutlined width="18px" />}>
                Statistics
              </Button>
            </Link>
            <Button
              type="primary"
              shape="round"
              size="large"
              icon={<PlusOutlined width="18px" />}
              onClick={() => setIsVisibleCreateModal(true)}
            >
              New Activity
            </Button>
          </Space>
        </Row>
      </Page.Header>

      <Page.Content className={css.content} ref={contentRef}>
        <Layout style={{ background: 'none' }}>
          <Layout.Content>
            <Feed program={program} />
          </Layout.Content>
          <Layout.Sider width={340} style={{ background: 'none' }}>
            <Affix offsetTop={54} target={() => contentRef.current}>
              <div className={css.preview}>
                <iframe src={liveUrl} frameBorder="0" title="lineup preview" />
                <img src={phone} alt="" />
              </div>
            </Affix>
          </Layout.Sider>
        </Layout>
      </Page.Content>

      <Modal
        centered
        visible={isVisibleCreateModal}
        onCancel={handleClose}
        width={970}
        closable={false}
        footer={null}
        destroyOnClose
      >
        <CreateActivity handleClose={handleClose} onSubmit={setNewActivity} programId={program.id} />
      </Modal>
    </div>
  );
};

export default Lineup;
