import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { Anchor } from 'antd';
import { NavLink } from 'react-router-dom';
import css from './SideNavigation.module.css';

export interface AnchorProps {
  title: string;
  links: Array<{ title: string; id: string }>;
  navType: 'anchor';
}

export interface NavProps {
  title: string;
  links: Array<{ title: string; to: string }>;
  navType: 'nav';
}

function isAnchorProps(props: Props): props is AnchorProps {
  return props.navType === 'anchor';
}

export const ANCHOR_SECTION_CLASSNAME = 'ant-anchor-section';

type Props = AnchorProps | NavProps;

const NavLinks = (props: Pick<NavProps, 'links'>) => (
  <nav className={css.links}>
    {props.links.map((link) =>
      link.to ? (
        <NavLink key={`navigation-${link.to}`} to={link.to} className={cn(css.link, 'text-subheading')}>
          {link.title}
        </NavLink>
      ) : null
    )}
  </nav>
);

const AnchorLinks = (props: Pick<AnchorProps, 'links'>) => {
  const [targetOffset, setTargetOffset] = useState<number | undefined>(undefined);

  useEffect(() => {
    setTargetOffset(window.innerHeight / 2);
  }, []);

  // manage "active" className for a related to anchor sections
  const onChange = useCallback((currentActiveLink: string) => {
    const sections = document.querySelectorAll(`.${ANCHOR_SECTION_CLASSNAME}`);
    sections?.forEach((section) => {
      if (section.id && section.id === currentActiveLink.replace('#', '')) {
        section.classList.add('active');
      } else {
        section.classList.remove('active');
      }
    });
  }, []);

  return (
    <Anchor
      className={css.links}
      targetOffset={targetOffset}
      onChange={onChange}
      onClick={(e) => {
        // prevent hashtag adding to url
        e.preventDefault();
      }}
    >
      {props.links.map((link) => (
        <Anchor.Link
          key={`navigation-${link.id}`}
          title={link.title}
          href={`#${link.id}`}
          className={cn(css.link, 'text-subheading')}
        />
      ))}
    </Anchor>
  );
};

const Links = {
  NavLinks,
  AnchorLinks,
};

const SideNavigation = (props: Props) => (
  <div className={css.nav}>
    <div className={cn(css.title, 'text-body')}>{props.title}</div>
    {isAnchorProps(props) ? <Links.AnchorLinks links={props.links} /> : <Links.NavLinks links={props.links} />}
  </div>
);

export default SideNavigation;
