import React from 'react';
import { find } from 'lodash';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useGetProgramStatsQuery } from 'app/api';
import { Activity, PollActivity, Program, ActivityTypes } from 'app/api/generated';
import { RisingStartAnswerId, YesNoAnswerId } from 'features/program/types';
import VotesContent, { getInitialValues, VotesData } from './VotesContent';

interface UseMainValuesProps {
  programId: string;
  activityId: string;
  activity: Activity;
}
const useMainValues = ({
  activity,
  programId,
}: UseMainValuesProps): { data: VotesData | null; refetch: () => void; status: QueryStatus } => {
  const query = useGetProgramStatsQuery({ id: programId });
  const programStats = query.data?.getProgramStats;
  const defaultResult = {
    refetch: query.refetch,
    status: query.status,
    data: null,
  };
  const activityStats = find(programStats?.activities, ['id', activity.id]);

  if (!programStats || !activityStats) {
    return defaultResult;
  }

  switch (activity.type) {
    case ActivityTypes.RisingStar:
      return {
        ...defaultResult,
        data: [
          {
            answer: { value: 'Yes' },
            votes: find(activityStats?.votes, ['answerId', RisingStartAnswerId.Yes])?.total ?? 0,
          },
          {
            answer: { value: 'No' },
            votes: find(activityStats?.votes, ['answerId', RisingStartAnswerId.No])?.total ?? 0,
          },
          { answer: { value: 'CheckIn' }, votes: activityStats.checkins },
        ],
      };
    case ActivityTypes.YesNo:
      return {
        ...defaultResult,
        data: [
          { answer: { value: 'Yes' }, votes: find(activityStats?.votes, ['answerId', YesNoAnswerId.Yes])?.total ?? 0 },
          { answer: { value: 'No' }, votes: find(activityStats?.votes, ['answerId', YesNoAnswerId.No])?.total ?? 0 },
        ],
      };
    case ActivityTypes.Poll:
      return {
        ...defaultResult,
        data: (activity as PollActivity).answers.map((answer) => ({
          answer: { value: answer.xmlAnswerId },
          votes: find(activityStats?.votes, ['answerId', answer.xmlAnswerId])?.total ?? 0,
        })),
      };
    default:
      return defaultResult;
  }
};

/**
 * Component to render votes data received from backend
 */
const VotesMain: React.FC<{ activity: Activity; program: Program }> = (props) => {
  const { activity, program } = props;
  const activityType = activity.type;
  const isRisingStar = activityType === ActivityTypes.RisingStar;
  const isPoll = activityType === ActivityTypes.Poll;
  const isYesOrNo = activityType === ActivityTypes.YesNo;
  const {
    refetch,
    data: rawData,
    status,
  } = useMainValues({ programId: program.id, activityId: activity.id, activity });

  if (!rawData) {
    return <div className="text-title text-center">No data</div>;
  }

  const values: VotesData = getInitialValues()[activityType];
  let total = 0;

  // process data
  Object.entries(rawData).forEach(([key, value]) => {
    if (isRisingStar) {
      if (value.answer.value === 'Yes') values[0].votes = value.votes;
      else if (value.answer.value === 'No') values[1].votes = value.votes;
      else if (value.answer.value === 'CheckIn') total = value.votes;
    }

    if (isYesOrNo) {
      if (value.answer.value === 'Yes') values[0].votes = value.votes;
      else if (value.answer.value === 'No') values[1].votes = value.votes;

      total += value.votes;
    }

    if (isPoll) {
      const xmlAnswerId = value.answer.value;
      const poll = props.activity as PollActivity;
      const answer = find(poll.answers, ['xmlAnswerId', xmlAnswerId]);
      if (answer) {
        total += value.votes;
        values.push({
          answer: {
            value: answer.image || answer.text || xmlAnswerId,
            text: answer.text ?? '',
          },
          votes: value.votes,
        });
      }
    }
  });

  return (
    <VotesContent
      activity={activity}
      program={program}
      total={total}
      values={values}
      onRefreshClick={refetch}
      onRefreshClickStatus={status}
    />
  );
};

export default VotesMain;
