import React, { forwardRef, ReactNode } from 'react';
import cn from 'classnames';
import css from './Page.module.css';

const PageHeader = (props: { className?: string; children: ReactNode }) => (
  <div className={cn(css.pageHeader, props.className)}>{props.children}</div>
);

const PageContent = forwardRef<HTMLDivElement, { className?: string; children: ReactNode }>((props, ref) => {
  return (
    <div className={cn(css.pageContent, props.className)} ref={ref}>
      {props.children}
    </div>
  );
});

const Page = {
  Header: PageHeader,
  Content: PageContent,
};

export default Page;
