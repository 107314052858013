import React from 'react';
import { useStatsContext } from 'features/program/Stats';
import VotesMain from './VotesMain';
import VotesBackup from './VotesBackup';

const Votes = ({ type }: { type: 'main' | 'backup' }) => {
  const { activity, program } = useStatsContext();

  if (!activity) {
    return <div className="text-title text-center">Activity not found</div>;
  }

  switch (type) {
    case 'main':
      return <VotesMain program={program} activity={activity} />;
    case 'backup':
      return <VotesBackup program={program} activity={activity} />;
    default:
      return null;
  }
};

export default Votes;
