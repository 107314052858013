import { get } from 'lodash';
import { message } from 'antd';
import { configureStore, ThunkAction, Action, MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { api } from 'app/api';
import user from 'features/user/userSlice';
import counter from 'features/counter/counterSlice';

/**
 * Log a warning and show a toast!
 */
export const ErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (action.error && action.error.name !== 'ConditionError') {
    const responseMsg: string | undefined = get(action, 'meta.baseQueryMeta.response.errors.0.message');
    let msg: string | undefined = responseMsg?.slice(0, responseMsg.indexOf(' {'));

    if (!msg && action.payload && typeof action.payload == 'string') msg = action.payload;
    else if (!msg && typeof action.error.message === 'string') msg = action.error.message;

    message.error(`${action.type}: ${msg || 'Something went wrong!'}`);
  }

  return next(action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    counter, // sample
    user,
  },
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(ErrorLogger, api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
