import React, { useEffect, useMemo, useState } from 'react';
import { find, sumBy } from 'lodash';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getMessagesCount } from 'app/api/pubnub';
import { createPortal } from 'react-dom';
import { Activity, ActivityTypes, PollActivity, Program } from 'app/api/generated';
import VotesContent, { getInitialValues, VotesData } from './VotesContent';

interface UseBackupDataQueryI {
  programId: string;
  createdAt: Program['createdAt'];
  activityId: string;
  activityType: ActivityTypes;
  activityAnswers?: PollActivity['answers'];
}

export const useBackupDataQuery = ({
  programId,
  createdAt,
  activityId,
  activityType,
  activityAnswers,
}: UseBackupDataQueryI) => {
  const query = useMemo(() => {
    const channels: string[] = [];
    const isRisingStar = activityType === ActivityTypes.RisingStar;
    const isYesNo = activityType === ActivityTypes.YesNo;
    const isPoll = activityType === ActivityTypes.Poll;

    // get channels
    const channelPrefix = `${programId}_${activityId}`;
    if (isRisingStar) {
      channels.push(`${channelPrefix}_yes`, `${channelPrefix}_no`, `${channelPrefix}_checkin`);
    } else if (isYesNo) {
      channels.push(`${channelPrefix}_yes`, `${channelPrefix}_no`);
    } else if (isPoll) {
      activityAnswers?.forEach((answer) => {
        channels.push(`${channelPrefix}_${answer.xmlAnswerId}`);
      });
    }

    return { channels, timetoken: [`${+new Date(createdAt)}`] };
  }, [programId, activityId, activityType, activityAnswers, createdAt]);

  return query;
};

export const useBackupData = ({ channels, timetoken }: { channels: string[]; timetoken: string[] }) => {
  const [values, setValues] = useState<Record<string, number>>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function request() {
      setIsLoading(true);
      try {
        const response = await getMessagesCount(channels, timetoken);
        setValues(response);
      } catch (error) {
        message.error(`pubnub/getMessagesCount: ${(error as Error).message}`);
      }
      setIsLoading(false);
    }

    request();
  }, [channels, timetoken]);

  return { values, isLoading };
};

export const processData = ({
  rawData,
  activityType,
  activity,
  programId,
}: {
  rawData: Record<string, number>;
  activityType: ActivityTypes;
  activity: Activity;
  programId: string;
}) => {
  const isRisingStar = activityType === ActivityTypes.RisingStar;
  const isPoll = activityType === ActivityTypes.Poll;
  const isYesNo = activityType === ActivityTypes.YesNo;
  const values: VotesData = getInitialValues()[activityType];
  let total = 0;

  // process data
  Object.entries(rawData).forEach(([key, value]) => {
    if (isRisingStar) {
      if (key.endsWith('_yes')) values[0].votes = value;
      else if (key.endsWith('_no')) values[1].votes = value;
      else if (key.endsWith('_checkin')) total = value;
    }

    if (isYesNo) {
      if (key.endsWith('_yes')) values[0].votes = value;
      else if (key.endsWith('_no')) values[1].votes = value;
    }

    if (isPoll) {
      const xmlAnswerId = key.substring(programId.length + activity.id.length + 2); // 2 - is count of "_" separators
      const poll = activity as PollActivity;
      const answer = find(poll.answers, ['xmlAnswerId', xmlAnswerId]);
      if (answer) {
        total += value;
        values.push({
          answer: {
            value: answer.image || answer.text || xmlAnswerId,
            text: answer.text ?? '',
          },
          votes: value,
          xmlAnswerId,
        });
      }
    }
  });

  if (!total) {
    total = sumBy(values, 'votes');
  }

  return { total, values };
};

/**
 * Component to render votes data received from backup storage (currently it is "PubNub")
 */
const VotesBackup: React.FC<{ activity: Activity; program: Program }> = (props) => {
  const { activity, program } = props;
  const activityType = activity.type;

  const query = useBackupDataQuery({
    programId: program.id,
    createdAt: program.createdAt,
    activityId: activity.id,
    activityType,
    activityAnswers: (activity as PollActivity)?.answers,
  });
  const { values: rawData, isLoading } = useBackupData(query);

  if (isLoading || !rawData) {
    return (
      <Spin
        size="large"
        className="fullscreen-spin"
        tip="Fetching..."
        indicator={<LoadingOutlined style={{ fontSize: 48, marginBottom: 16 }} spin />}
      />
    );
  }

  const { values, total } = processData({ rawData, activityType, activity, programId: program.id });

  return (
    <>
      <VotesContent activity={activity} program={program} total={total} values={values} />
      {createPortal(<style>{'body, #root > * { background: #f3f2de }'}</style>, document.body)}
    </>
  );
};

export default VotesBackup;
