import React, { useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { generatePath, Link } from 'react-router-dom';
import { Table, Skeleton, Space } from 'antd';
import { CaretUpOutlined, RedoOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import {
  useActivateProgramMutation,
  useDeleteProgramMutation,
  useGetProgramsQuery,
  useDuplicateProgramMutation,
} from 'app/api';
import { OrderDirection } from 'app/api/generated';
import { PROGRAM_EDIT_ROUTE, PROGRAM_LINEUP_ROUTE, PROGRAM_LINEUP_VIEWER_ROUTE } from 'common/constants/routes';
import { isHebrew } from 'common/utils';
import Button from 'common/components/Button';
import Icons from 'common/components/Icons';
import { useProgramsContext } from '../Programs';
import css from './ProgramsTable.module.css';

interface DataType {
  name: string;
  id: string;
  updatedAt: any;
  key: React.Key;
}

const PAGE_SIZE = 20;
const DEFAULT_PAGE = 1;
const DEFAULT_DIRECTION = 'descend';
const DEFAULT_ORDER_BY = 'updatedAt';

const ProgramsTable: React.FC<{ active?: boolean }> = ({ active = false }) => {
  const { term } = useProgramsContext();
  const [input, setInput] = useState<{ page: number; direction: 'descend' | 'ascend' | null; orderBy: string | null }>({
    page: DEFAULT_PAGE,
    direction: DEFAULT_DIRECTION,
    orderBy: DEFAULT_ORDER_BY,
  });
  const { data, isFetching, refetch, isError } = useGetProgramsQuery(
    {
      input: {
        active,
        term,
        limit: PAGE_SIZE,
        offset: (input.page - 1) * PAGE_SIZE,
        direction:
          input.direction === 'descend' ? OrderDirection.Descending : input.direction ? OrderDirection.Ascending : null,
        orderBy: input.orderBy,
      },
    },
    { refetchOnMountOrArgChange: true }
  );
  const [duplicateProgram, duplicateProgramData] = useDuplicateProgramMutation();
  const [deleteProgram, deleteProgramData] = useDeleteProgramMutation();
  const [activateProgram, activateProgramData] = useActivateProgramMutation();
  const programs = isError ? [] : data?.getPrograms?.programs ?? [];
  const total = data?.getPrograms?.total ?? 0;

  if (isFetching) {
    return (
      <>
        <Space size="large" style={{ marginBottom: 20, marginTop: 20 }}>
          <Skeleton.Button active size="large" shape="square" />
          <div style={{ width: '33.5vw' }} />
          <Skeleton.Button active size="large" shape="square" />
        </Space>
        <Skeleton.Input active style={{ height: 50, marginBottom: 20 }} block />
        <Skeleton.Input active style={{ height: 50, marginBottom: 20 }} block />
        <Skeleton.Input active style={{ height: 50, marginBottom: 20 }} block />
        <Skeleton.Input active style={{ height: 50, marginBottom: 20 }} block />
        <Skeleton.Input active style={{ height: 50, marginBottom: 20 }} block />
        <Skeleton.Input active style={{ height: 50, marginBottom: 500 }} block />
      </>
    );
  }

  const columns: ColumnsType<DataType> = [
    {
      title: () => (
        <>
          Name
          <CaretUpOutlined className="ant-custom-filter-icon" />
        </>
      ),
      dataIndex: 'name',
      sortOrder: input.orderBy === 'name' ? input.direction : null,
      sorter: true,
      render(name, { id }) {
        return (
          <Link
            to={generatePath(PROGRAM_EDIT_ROUTE, { programId: id })}
            className={isHebrew(name) ? 'text-hebrew' : ''}
          >
            {name}
          </Link>
        );
      },
    },
    {
      title: () => (
        <>
          Date
          <CaretUpOutlined className="ant-custom-filter-icon" />
        </>
      ),
      dataIndex: 'updatedAt',
      sortOrder: input.orderBy === 'updatedAt' ? input.direction : null,
      sorter: true,
      defaultSortOrder: input.direction,
      width: '15%',
      render(date) {
        return <span style={{ letterSpacing: '-0.9px' }}>{moment(date).format('DD/MM/YYYY')}</span>;
      },
    },
    {
      dataIndex: 'id',
      width: '25%',
      render(id, program) {
        return (
          <div className={css.actions}>
            <Link to={generatePath(PROGRAM_EDIT_ROUTE, { programId: id })}>
              <Button customType="accent" shape="circle" icon={<Icons.Edit />} title="Edit" />
            </Link>
            <Link to={generatePath(PROGRAM_LINEUP_ROUTE, { programId: id })}>
              <Button type="primary" shape="circle" icon={<Icons.Lineup />} title="Lineup" />
            </Link>
            <Link to={generatePath(PROGRAM_LINEUP_VIEWER_ROUTE, { programId: id })}>
              <Button shape="circle" icon={<Icons.Lineup />} title="Lineup (Viewer mode)" />
            </Link>
            {active && (
              <Button
                customType="dimmed"
                shape="circle"
                icon={<Icons.Duplicate />}
                loading={duplicateProgramData.isLoading}
                onClick={async () => {
                  await duplicateProgram({ id });
                  refetch();
                }}
              />
            )}
            <Button
              danger
              shape="circle"
              title={active ? 'Archive' : 'Restore'}
              icon={active ? <Icons.Archive /> : <RedoOutlined />}
              loading={deleteProgramData.isLoading || activateProgramData.isLoading}
              onClick={async () => {
                if (active) await deleteProgram({ id });
                else await activateProgram({ id });

                refetch();
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Table
      className={css.table}
      columns={columns}
      dataSource={programs.map((p) => ({ key: p.id, deleteProgram, ...p }))}
      onChange={(pagination, filters, sorter, extra) => {
        const orderBy = get(sorter as any, 'field', DEFAULT_ORDER_BY);
        const direction = get(sorter, 'order', DEFAULT_DIRECTION);
        const isReset = orderBy === input.orderBy && direction === input.direction;

        setInput({
          page: pagination.current ?? DEFAULT_PAGE,
          orderBy: isReset ? null : orderBy,
          direction: isReset ? null : direction,
        });
      }}
      pagination={{
        current: input.page,
        defaultCurrent: DEFAULT_PAGE,
        pageSize: PAGE_SIZE,
        total,
        showSizeChanger: false,
      }}
    />
  );
};

export default ProgramsTable;
