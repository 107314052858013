import { InputTextPropsI } from 'common/components/InputText';
import { TogglePropsI } from 'common/components/Toggle';
import { SelectPropsI } from 'common/components/Select';
import { UploadPropsI } from 'common/components/Upload';
import { InputColorPropsI } from 'common/components/InputColor';
import { TextAreaPropsI } from 'common/components/TextArea';

export enum RisingStartAnswerId {
  Yes = 'Yes',
  No = 'No',
}

export enum YesNoAnswerId {
  Yes = 'Yes',
  No = 'No',
}

// Program page config
export type SectionID = 'general' | 'registration' | 'tos' | 'graphics' | 'pixels';

export type SectionsType = Record<SectionID, { id: SectionID; title: string }>;

// type guard
export function isRegistrationFieldConfig(fieldConfig: FieldConfigType): fieldConfig is RegistrationFieldsConfig {
  return fieldConfig.componentType === 'registrationFields';
}

type RegistrationFieldsConfig = { componentType: 'registrationFields' };

export type FieldConfigType =
  | ({
      componentType: 'textarea' | 'toggle' | 'text' | 'select' | 'file' | 'color';
      name: string;
      defaultValuePath?: string;
    } & (InputTextPropsI | TogglePropsI | SelectPropsI | UploadPropsI | InputColorPropsI | TextAreaPropsI))
  | RegistrationFieldsConfig;
