import * as y from 'yup';
import { RegistrationType, Program } from 'app/api/generated';

function requiredStringWhen(when: string) {
  return y
    .string()
    .optional()
    .when(when, {
      is: true,
      then: y.string().required('Required'),
    });
}

function requiredBooleanWhen(when: string) {
  return y
    .boolean()
    .optional()
    .when(when, {
      is: true,
      then: y.boolean().required('Required'),
    });
}

const RegistrationFieldsSchema = y.array().of(
  y
    .object({
      active: y.boolean().required(),
      label: y.string().optional().nullable(),
      name: y.string().required(),
      required: y.boolean().required(),
    })
    .default({
      active: false,
      required: false,
      name: 'default',
    })
);

const ValidationSchema: y.SchemaOf<Pick<Program, 'id' | 'active' | 'name' | 'theme' | 'registration' | 'tos'>> = y
  .object()
  .shape({
    id: y.string().required(),
    active: y.boolean().required(),
    name: y.string().min(2, 'Min 2 characters').max(100, 'Max 100 characters').required('Required'),
    slug: y
      .string()
      .min(2, 'Min 2 characters')
      .max(100, 'Max 100 characters')
      .matches(/^[aA-zZ0-9_]+$/, 'Only numbers, english letters and "_" are allowed')
      .nullable(),
    registration: y
      .object()
      .shape({
        active: y.boolean().required(),
        type: y
          .mixed<RegistrationType>()
          .oneOf(Object.values(RegistrationType))
          .required()
          .default(RegistrationType.Form),
        title: requiredStringWhen('active'),
        subtitle: requiredStringWhen('active'),
        pic: y.string().nullable().optional(),
        submit: requiredStringWhen('active'),
        dismiss: requiredStringWhen('showDismiss'),
        showDismiss: requiredBooleanWhen('active').default(false),
        fields: RegistrationFieldsSchema.when('active', {
          is: true,
          then: RegistrationFieldsSchema.required(),
        }).nullable(),
      })
      .nullable(),
    tos: y
      .object()
      .shape({
        active: y.boolean().required(),
        showCheckbox: requiredBooleanWhen('active').default(true),
        text: requiredStringWhen('active'),
        url: requiredStringWhen('active'),
      })
      .nullable(),
    theme: y
      .object({
        bgColor: y.string().optional().nullable(),
        bgImage: y.string().optional().nullable(),
        btnBgColor: y.string().optional().nullable(),
        btnColor: y.string().optional().nullable(),
        dimmedColor: y.string().optional().nullable(),
        logo: y.string().optional().nullable(),
        logoPlacement: y.string().optional().nullable(),
        primaryColor: y.string().optional().nullable(),
      })
      .nullable(),
  });

export const MAX_ACTIVITY_NAME_LEN = 120;

export default ValidationSchema;
