import React, { memo, ReactNode } from 'react';
import cn from 'classnames';
import { Input, InputProps } from 'antd';
import { isHebrew } from 'common/utils';
import Error from 'common/components/Error';
import css from './InputText.module.css';

export interface InputTextPropsI extends InputProps {
  id?: string;
  label?: ReactNode;
  labelHint?: ReactNode;
  error?: string;
  touched?: boolean;
  component?: 'Password';
  preset?: 'text-hero'; // text-hero -> big centered input without shadows and borders
}

const InputText = ({ label, labelHint, touched, error, component, className, preset, ...props }: InputTextPropsI) => {
  const Component = component ? Input[component] : Input;
  const id = props.id || props.name;

  return (
    <div
      className={cn(css.field, className, {
        [css.withCount]: props.showCount,
        [css.textHero]: preset === 'text-hero',
      })}
    >
      {(label || labelHint) && (
        <label htmlFor={id} className="text-label">
          {label}
          {labelHint ? <span className={cn(css.hint, 'text-footnote')}>{labelHint}</span> : null}
        </label>
      )}
      <Component
        {...props}
        className={cn({ 'text-hebrew': isHebrew((props.value as string) || props.placeholder) })}
        id={id}
      />
      {error ? <Error>{error}</Error> : null}
    </div>
  );
};

export default memo(InputText);
