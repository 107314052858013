import React, { useState } from 'react';
import { generatePath, NavLink, Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { Input, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { withErrorBoundary } from '@sentry/react';
import { PROGRAM_EDIT_ROUTE, PROGRAMS_ALL_ROUTE, PROGRAMS_ARCHIVE_ROUTE, USERS_ROUTE } from 'common/constants/routes';
import Page from 'common/components/Page';
import { NavProps } from 'common/components/SideNavigation';
import Button from 'common/components/Button';

import { newProgram } from 'features/program/constants';
import ProgramsTable from './ProgramsTable';

import css from './Programs.module.css';
import cssSearch from './Search.module.css';

type ContextType = { term: string };

export function useProgramsContext() {
  return useOutletContext<ContextType>();
}

const ProgramsLayout = () => {
  const [term, setTerm] = useState('');
  const navigate = useNavigate();

  return (
    <>
      <Page.Header>
        <Row align="middle" justify="space-between">
          <h1 className="text-title">All Programs</h1>
          <Button
            type="primary"
            shape="round"
            size="large"
            icon={<PlusOutlined width="18px" />}
            onClick={() => navigate(generatePath(PROGRAM_EDIT_ROUTE, { programId: newProgram.id }))}
          >
            New
          </Button>
        </Row>
      </Page.Header>

      <Page.Content>
        <Row align="stretch" justify="space-between" className={css.contentHeader}>
          <nav>
            <NavLink to={PROGRAMS_ALL_ROUTE} className="text-subheading">
              Active programs
            </NavLink>
            <NavLink to={PROGRAMS_ARCHIVE_ROUTE} className="text-subheading">
              Archive
            </NavLink>
          </nav>
          <Input.Search className={cssSearch.search} placeholder="Search" allowClear onSearch={setTerm} />
        </Row>

        {/* child routes */}
        <Outlet context={{ term }} />
      </Page.Content>
    </>
  );
};

export const programsNavigation: NavProps = {
  navType: 'nav',
  title: 'Menu',
  links: [
    { to: PROGRAMS_ALL_ROUTE, title: 'All Programs' },
    { to: USERS_ROUTE, title: 'Manage Users' },
  ],
};

const Programs = {
  Layout: withErrorBoundary(ProgramsLayout, { fallback: <p>An error has occurred</p> }),
  Table: ProgramsTable,
};

export default Programs;
